import { getMarketingPreferencesEndpoint, MarketingPreference, MarketingPreferenceType } from 'common';
import {
    DataOverview,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedCheckbox,
} from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CpDataApi } from '../../../../../cp-xhr';
import { useScrollTo } from '../../../../../utils/use-scroll-to';
import { ConfirmationModal } from '../../../../hook-page/ConfirmationModal';
import { TrackingPageName } from '../../../../hook-page/user-details-hookpages/marketing-preferences-hookpage/EditView/EditView';
import { MarketingPreferencesEmail } from '../../../../marketing-preferences-email';
import { dashboardPagePath } from '../../../../navigation/paths';
import { EditStatus } from '../../../contact-section/EditStatus';
import { NoConnectionView } from '../no-connection-view/NoConnectionView';
import { getMarketingPreferencesInitialValues, MarketingPreferencesFormValues } from './initialValues';
import {
    isDisabledMarketingPreferencesSubmit,
    isShownMarketingPreferencesEmail,
    prepareMarketingPreferencesRequestBody,
} from './utils';
import { marketingPreferencesEmailValidationSchema } from './validationSchema';

export type EditViewProps = {
    marketingPreference?: MarketingPreference;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus) => void;
    marketingPreferenceType: MarketingPreferenceType;
    isContactEmailDefined: boolean;
};

export const CommonEditView: React.FC<EditViewProps> = ({
    marketingPreference,
    cancelEditing,
    finishEditing,
    marketingPreferenceType,
    isContactEmailDefined,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { t } = useTranslation(['my-profile-marketing-preferences', 'marketing-preferences-hookpage']);
    const history = useHistory();
    const { onAction: onSaveNo } = useAnalyticsActionTracker('onMarketingAgreementSaveNo');

    const scrollToRef = useScrollTo(isSubmitting ? 'SUBMITTING_VIEW' : 'EDIT_VIEW', 'EDIT_VIEW', 100);

    const trackingSectionType =
        marketingPreferenceType === MarketingPreferenceType.VWFS ? '(VWFS)' : '(Business partner)';
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileConsentSettingsTypedIn',
    });
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, `Consent settings ${trackingSectionType}`);
    const { onAction: onChanged } = useAnalyticsActionTracker('onMarketingAgreementChanged');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileConsentSettingsSubmitFailed',
        confirmSuccess: 'onEditProfileConsentSettingsSuccess',
    });

    if (!marketingPreference) {
        return <NoConnectionView cancelEditing={cancelEditing} />;
    }

    const type = marketingPreferenceType === MarketingPreferenceType.VWFS ? 'vwfs' : 'business-partners';

    const initialValues = getMarketingPreferencesInitialValues(marketingPreference);

    const closeButtonProps = {
        onClick: cancelEditing,
        className: 'c-icon  c-icon--[semantic-close]',
        testId: 'x-button',
    };

    const onSubmit = (values: MarketingPreferencesFormValues): void => {
        setIsSubmitting(true);
        const body: MarketingPreference = prepareMarketingPreferencesRequestBody(values);
        CpDataApi.put(getMarketingPreferencesEndpoint(), body, { params: { type: marketingPreferenceType } })
            .then(() => {
                setIsSubmitting(false);
                finishEditing(EditStatus.SUCCESS);
                onSuccess(trackingSectionType, values);
                onChanged(values, initialValues, trackingSectionType, TrackingPageName.PROFILE);
            })
            .catch(() => {
                setIsSubmitting(false);
                finishEditing(EditStatus.ERROR);
                onError(trackingSectionType);
            });
    };

    const onDashboardButtonClick = (): void => {
        onSaveNo();
        history.push(dashboardPagePath());
    };

    return (
        <>
            <section ref={scrollToRef}>
                <DataOverview title={t(`edit-view.title.${type}`)} buttonLabel={' '} buttonProps={closeButtonProps}>
                    <UiBlockingSpinner isBlocking={isSubmitting}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={marketingPreferencesEmailValidationSchema(t, isContactEmailDefined)}
                        >
                            {({ submitForm, errors, touched, values, isValid }): JSX.Element => (
                                <>
                                    <Form
                                        onSubmit={preventSubmit()}
                                        data-testid="edit-form"
                                        onChange={(): void => {
                                            onTyping(errors, touched, trackingSectionType);
                                        }}
                                    >
                                        <Fieldset>
                                            <Fieldset.Row>
                                                {Object.keys(initialValues.preferences).map(key => (
                                                    <section key={key}>
                                                        <div className={'u-mb-xsmall'}>
                                                            <ValidatedCheckbox
                                                                label={t(`edit-view.${key}`)}
                                                                name={`preferences.${key}`}
                                                                testId={`${key}-checkbox`}
                                                            />
                                                        </div>
                                                        {isShownMarketingPreferencesEmail(
                                                            key,
                                                            values,
                                                            isContactEmailDefined,
                                                        ) && <MarketingPreferencesEmail />}
                                                    </section>
                                                ))}
                                            </Fieldset.Row>
                                        </Fieldset>
                                        <Fieldset>
                                            <Fieldset.Row>
                                                <ButtonContainer center>
                                                    <Button
                                                        secondary
                                                        onClick={cancelEditing}
                                                        testId="cancel-button"
                                                        type="button"
                                                    >
                                                        {t('translation:editable-section-nav.cancel')}
                                                    </Button>
                                                    <Button
                                                        onClick={submitForm}
                                                        testId="submit-button"
                                                        type="submit"
                                                        disabled={isDisabledMarketingPreferencesSubmit(
                                                            !!values.preferences.email,
                                                            isContactEmailDefined,
                                                            !!touched.emailAddress,
                                                            isValid,
                                                        )}
                                                    >
                                                        {t('edit-view.confirm-button')}
                                                    </Button>
                                                </ButtonContainer>
                                            </Fieldset.Row>
                                        </Fieldset>
                                    </Form>
                                    <ConfirmationModal
                                        showModal={showConfirmationModal}
                                        title={t('modal.heading')}
                                        text={t('modal.text')}
                                        onSecondaryClick={onDashboardButtonClick}
                                        onSubmitClick={submitForm}
                                        onModalClose={(): void => setShowConfirmationModal(false)}
                                        testId={'marketing-pref-modal-testId'}
                                    />
                                </>
                            )}
                        </Formik>
                    </UiBlockingSpinner>
                </DataOverview>
            </section>
        </>
    );
};

import React from 'react';
import { Button, ButtonContainer, Heading, Layout, Card } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { DealerDetails } from 'common';
import { ContractEndOptionsView } from '../../enums';
import { dashboardPagePath } from '../../../../navigation/paths';
import { DealerInformationText } from '../../../../marketing-cards/ui/dealer-information-card';
import { DealerMap } from '../../../../dealer-map';

export const DealerInformationViewUi: React.FC<{
    handleBackClick: (contractEndOptionsView: ContractEndOptionsView) => void;
    dealerDetails?: DealerDetails;
}> = ({ handleBackClick, dealerDetails }) => {
    const { t } = useTranslation('contract-end-options');
    const history = useHistory();

    if (!dealerDetails) {
        return null;
    }

    return (
        <Layout>
            <Layout.Item>
                <Heading className="u-mt" level={3}>
                    {t('dealer-information.sub-headline')}
                </Heading>
                <p>{t('dealer-information.description')}</p>
            </Layout.Item>
            <Layout.Item>
                <DealerMap dealerDetails={dealerDetails} height={'400px'} />
            </Layout.Item>
            <Layout.Item>
                <Card element="article" className={'u-text-left'}>
                    <DealerInformationText dealerDetails={dealerDetails} />
                </Card>
            </Layout.Item>
            <Layout.Item>
                <ButtonContainer center className={'u-mt'}>
                    <Button
                        secondary
                        testId="backButton"
                        onClick={(): void => handleBackClick(ContractEndOptionsView.OPTIONS_OVERVIEW)}
                    >
                        {t('translation:editable-section-nav.back')}
                    </Button>
                    <Button testId="backToDashboardButton" onClick={() => history.push(dashboardPagePath())}>
                        {t('translation:editable-section-nav.back-to-dashboard')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};

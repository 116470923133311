import { Brand, Contract } from 'common';
import { formatCpDate } from '@cp-shared-6/common-utilities';
import { findDigitalRenewalContractWithLatestEndDate } from 'utils';
import { isFirstRegistrationLocalStorageKey } from '../../../config/config';

export const isUserDetailsHookPage = (lastLogin: string, lastContactDetailsHookPage: string): boolean => {
    const today = formatCpDate();

    if (today.diff(lastLogin, 'M', false) >= 3) {
        return true;
    } else if (today.diff(lastContactDetailsHookPage, 'M', false) >= 6 || lastContactDetailsHookPage === '') {
        return true;
    }

    return false;
};

export const isDigitalRenewalHookPage = (contracts?: Contract[]): Contract | undefined => {
    const digitalRenewalContract = findDigitalRenewalContractWithLatestEndDate(contracts);

    if (digitalRenewalContract && digitalRenewalContract.brand !== Brand.VWCV) {
        return digitalRenewalContract;
    } else {
        return undefined;
    }
};

export const isIntroductionHookPage = (): boolean => !!window.localStorage.getItem(isFirstRegistrationLocalStorageKey);

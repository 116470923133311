import { Brand, DealerDetails, MarketingCards } from 'common';
import { Carousel } from '@cp-shared-6/frontend-ui';
import React from 'react';
import { FlickityOptions } from 'react-flickity-component';
import { DealerInformationCard } from './dealer-information-card';
import { MarketingCardComponent } from './marketing-card-component';

type MarketingCardsUiProps = {
    marketingCards?: MarketingCards;
    onlineServicePlanUrl?: string;
    mostRecentContractBrand?: Brand;
    unbrandedOSPCard?: boolean;
    dealerDetails?: DealerDetails;
};

export const MarketingCardsUi: React.FC<MarketingCardsUiProps> = ({
    marketingCards,
    onlineServicePlanUrl,
    mostRecentContractBrand,
    unbrandedOSPCard,
    dealerDetails,
}) => {
    if (!marketingCards) {
        return null;
    }

    const { onlineServicePlan, allianzMotorInsurance } = marketingCards;

    const getAllianzInsuranceBrand = (brand?: Brand): 'audi' | 'seat' | 'vw' | undefined => {
        switch (brand) {
            case Brand.AUDI:
                return 'audi';
            case Brand.SEAT:
                return 'seat';
            case Brand.VW:
                return 'vw';
            default:
                return undefined;
        }
    };
    const getOnlineServicePlanBrand = (brand?: Brand): 'audi' | 'seat' | 'skoda' | 'vw' | 'cupra' | 'unbranded' => {
        switch (brand) {
            case Brand.AUDI:
                return 'audi';
            case Brand.SEAT:
                return 'seat';
            case Brand.SKODA:
                return 'skoda';
            case Brand.VW:
                return 'vw';
            case Brand.CUPRA:
                return 'cupra';
            default:
                return 'unbranded';
        }
    };
    let brandState = mostRecentContractBrand;
    if (unbrandedOSPCard) {
        brandState = undefined;
    }

    const allianzInsuranceBrand = getAllianzInsuranceBrand(mostRecentContractBrand);
    const onlineServicePlanBrand = getOnlineServicePlanBrand(brandState);

    const options: FlickityOptions = {
        adaptiveHeight: false,
        autoPlay: 10000,
    };

    return (
        <>
            {dealerDetails && mostRecentContractBrand && (
                <DealerInformationCard
                    dealerDetails={dealerDetails}
                    mostRecentContractBrand={mostRecentContractBrand}
                />
            )}
            <Carousel className={'u-mt'} options={options}>
                {onlineServicePlanUrl && (
                    <MarketingCardComponent
                        content={onlineServicePlan[onlineServicePlanBrand]}
                        buttonUrl={`${onlineServicePlanUrl}${onlineServicePlan[onlineServicePlanBrand].traciLink}`}
                        testId={'osp-marketing-card'}
                        shouldOpenLinkInNewWindow
                    />
                )}
                {allianzInsuranceBrand && (
                    <MarketingCardComponent
                        content={allianzMotorInsurance[allianzInsuranceBrand]}
                        buttonUrl={allianzMotorInsurance[allianzInsuranceBrand].buttonUrl}
                        testId={`allianz-marketing-card-${allianzInsuranceBrand}`}
                        shouldOpenLinkInNewWindow
                    />
                )}
            </Carousel>
        </>
    );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionCreatorWithPayload, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Reducer } from 'redux';

import { AppThunk } from './sliceTypeDefinitions';

export enum FetchContentError {
    DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type FetchContentType = (locale: string) => AppThunk;

export type AbstractContentState<TDto> = {
    cmsContent?: TDto;
    isLoading: boolean;
    loadingError?: FetchContentError;
};

export type CmsContentSlice<TState> = {
    reducer: Reducer<TState>;
    fetchContent: FetchContentType;
};

export function createCmsContentSlice<TDto>({
    contentName,
    initialContent,
    contentCallback,
}: {
    contentName: string;
    initialContent?: TDto;
    contentCallback: (locale: string) => Promise<TDto>;
}): CmsContentSlice<AbstractContentState<TDto>> {
    const contentSlice = createSlice({
        name: contentName,
        initialState: { isLoading: false, cmsContent: initialContent } as AbstractContentState<TDto>,
        reducers: {
            startFetching(state) {
                state.isLoading = true;
                state.loadingError = undefined;
            },
            fetchContentSuccess(state, action: PayloadAction<TDto>) {
                (state.cmsContent as any) = action.payload;
                state.loadingError = undefined;
                state.isLoading = false;
            },
            fetchContentFailure(state, action: PayloadAction<FetchContentError>) {
                (state.cmsContent as any) = undefined;
                state.loadingError = action.payload;
                state.isLoading = false;
            },
        },
    });

    const { fetchContentSuccess, fetchContentFailure, startFetching } = contentSlice.actions;

    const fetchContent = (locale: string): AppThunk => async dispatch => {
        try {
            dispatch(startFetching());
            const data = await contentCallback(locale);
            dispatch((fetchContentSuccess as ActionCreatorWithPayload<TDto>)(data));
        } catch (error) {
            let errorCode = FetchContentError.DEFAULT_ERROR;
            if (error.response && error.response.data && error.response.data) {
                const { code = FetchContentError.DEFAULT_ERROR } = error.response.data;
                errorCode = code;
            }
            dispatch(fetchContentFailure(errorCode));
        }
    };

    return {
        reducer: contentSlice.reducer,
        fetchContent,
    };
}

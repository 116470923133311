import { Error, WithDefaultBusinessMarketApiError } from '@cp-shared-6/common-utilities';
import { IbanError } from 'common';
import { BankData, SignedData } from '@cp-shared-6/apis';

const bankData: BankData = {
    isValid: true,
    bankDetails: {
        iban: 'IBAN',
        bankName: 'Bank name',
    },
};
export const IbanValidationSuccess: SignedData<BankData> = {
    data: bankData,
    checksum: 'checksum',
};

export const IbanValidationSameBankAccount: Error<IbanError> = {
    status: 502,
    code: 'SAME_BANK_ACCOUNT',
    message: 'Still the same bank account',
};

export const IbanValidationBusinessError: Error<WithDefaultBusinessMarketApiError<IbanError>> = {
    status: 502,
    code: 'MARKET_API_DEFAULT_BUSINESS_ERROR',
    message: 'Something went wrong.',
};

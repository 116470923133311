import { getMarketingPreferencesEndpoint, MarketingPreference, MarketingPreferenceType } from 'common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    useAnalyticsActionTracker,
    ValidatedCheckbox,
} from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, Fieldset, Form, Modal } from '@vwfs-bronson/bronson-react';
import { ConfirmationModal } from 'components/hook-page/ConfirmationModal';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CpDataApi } from '../../../../../cp-xhr';
import { MarketingPreferencesEmail } from '../../../../marketing-preferences-email';
import {
    getMarketingPreferencesInitialValues,
    isDisabledMarketingPreferencesSubmit,
    isShownMarketingPreferencesEmail,
    marketingPreferencesEmailValidationSchema,
    MarketingPreferencesFormValues,
    prepareMarketingPreferencesRequestBody,
} from '../../../../my-profile/marketing-preferences-sections/common/edit-view';
import { EditStatus } from '../../../../my-profile/marketing-preferences-sections/common/EditStatus';
import { dashboardPagePath } from '../../../../navigation/paths';

export enum TrackingPageName {
    HOOKPAGE = 'Hookpage',
    PROFILE = 'Profile',
}

export type EditViewProps = {
    setIsLoading: (isLoading: boolean) => void;
    marketingPreference?: MarketingPreference;
    isContactEmailDefined: boolean;
};

export const MarketingPreferencesEditView: React.FC<EditViewProps> = ({
    setIsLoading,
    marketingPreference,
    isContactEmailDefined,
}) => {
    const [editStatus, setEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { t } = useTranslation(['marketing-preferences-hookpage', 'my-profile-contact-details']);
    const history = useHistory();
    const { onAction: onSaveYes } = useAnalyticsActionTracker('onMarketingAgreementSaveYes');
    const { onAction: onSaveNo } = useAnalyticsActionTracker('onMarketingAgreementSaveNo');
    const { onAction: onChanged } = useAnalyticsActionTracker('onMarketingAgreementChanged');
    const { onAction: onSubmitChanges } = useAnalyticsActionTracker('onMarketingAgreementSubmitChanges');
    const { onAction: onCancelWithoutChanges } = useAnalyticsActionTracker('onMarketingAgreementCancelWithoutChanges');

    const initialValues = getMarketingPreferencesInitialValues(marketingPreference);
    const onSubmit = (values: MarketingPreferencesFormValues): void => {
        if (!!values.emailAddress) {
            onSubmitChanges();
        }
        setIsLoading(true);
        const body: MarketingPreference = prepareMarketingPreferencesRequestBody(values);
        CpDataApi.put(getMarketingPreferencesEndpoint(), body, { params: { type: MarketingPreferenceType.VWFS } })
            .then(() => {
                setEditStatus(EditStatus.SUCCESS);
                onSaveYes(values);
                onChanged(values, initialValues, '(VWFS)', TrackingPageName.HOOKPAGE);
            })
            .catch(() => {
                setEditStatus(EditStatus.ERROR);
            })
            .finally(() => {
                setIsLoading(false);
                setShowConfirmationModal(false);
            });
    };

    const onDashboardButtonClick = (): void => {
        onSaveNo();
        history.push(dashboardPagePath());
    };

    const onSecondaryClick = (dirty: boolean, isValid: boolean, values: MarketingPreferencesFormValues) => {
        if (dirty && !!values.emailAddress) {
            onCancelWithoutChanges();
        }
        if (dirty && isValid) setShowConfirmationModal(true);
        else onDashboardButtonClick();
    };

    return (
        <>
            {editStatus === EditStatus.ERROR && (
                <Notification testId={'errorNotification'} status={NotificationStatus.error} className={'u-mb'}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('message.error.text'),
                        }}
                    />
                </Notification>
            )}
            <Modal
                shown={editStatus === EditStatus.SUCCESS}
                status={'success'}
                title={t('message.success.headline')}
                onConfirm={() => history.push(dashboardPagePath())}
                buttonConfirmText={t('message.success.button')}
                testId={'success-modal'}
                hideCloseButton
            >
                {t('message.success.text')}
            </Modal>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={marketingPreferencesEmailValidationSchema(t, isContactEmailDefined)}
            >
                {({ submitForm, values, isValid, touched, dirty }): JSX.Element => (
                    <>
                        <Form onSubmit={preventSubmit()} data-testid="marketing-preferences-form">
                            <Fieldset>
                                <Fieldset.Row>
                                    {Object.keys(initialValues.preferences).map(key => (
                                        <section key={key}>
                                            <div className={'u-mb-xsmall'}>
                                                <ValidatedCheckbox
                                                    label={t(`checkbox.${key}`)}
                                                    name={`preferences.${key}`}
                                                    testId={`${key}-checkbox`}
                                                />
                                            </div>
                                            {isShownMarketingPreferencesEmail(key, values, isContactEmailDefined) && (
                                                <MarketingPreferencesEmail />
                                            )}
                                        </section>
                                    ))}
                                </Fieldset.Row>
                            </Fieldset>
                            <ButtonContainer center>
                                <Button
                                    secondary
                                    testId="backButton"
                                    onClick={(): void => onSecondaryClick(dirty, isValid, values)}
                                >
                                    {t('button.secondary')}
                                </Button>
                                <Button
                                    onClick={submitForm}
                                    testId="submit-button"
                                    type="submit"
                                    disabled={isDisabledMarketingPreferencesSubmit(
                                        !!values.preferences.email,
                                        isContactEmailDefined,
                                        !!touched.emailAddress,
                                        isValid,
                                        dirty,
                                    )}
                                >
                                    {t('button.primary')}
                                </Button>
                            </ButtonContainer>
                        </Form>
                        <ConfirmationModal
                            showModal={showConfirmationModal}
                            title={t('modal.heading')}
                            text={t('modal.text')}
                            onSecondaryClick={onDashboardButtonClick}
                            onSubmitClick={submitForm}
                            onModalClose={(): void => setShowConfirmationModal(false)}
                            testId={'marketing-pref-modal-testId'}
                        />
                    </>
                )}
            </Formik>
        </>
    );
};

import React, { useState } from 'react';
import { CpDataApi } from 'cp-xhr';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-6/common-utilities';
import { parseErrorResponse } from '@cp-shared-6/frontend-integration';
import {
    CustomerRegistrationData,
    CustomerRegistrationError,
    CustomerRegistrationMultipleUserResult,
    CustomerRegistrationResult,
    getAccountVerificationRequestEndpoint,
} from 'common';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { IdentificationForm } from './identification-form';

type IdentificationProps = {
    legalNotice: string;
    onSubmitSuccess: (
        registrationResponse: CustomerRegistrationResult | CustomerRegistrationMultipleUserResult,
        customerData: CustomerRegistrationData,
    ) => void;
    setLastTouchedField: Function;
};

type RegistrationRequestProps = {
    isLoading: boolean;
    errorCode?: WithDefaultCpIntegrationErrors<CustomerRegistrationError>;
};

export const Identification: React.FC<IdentificationProps> = ({
    legalNotice,
    onSubmitSuccess,
    setLastTouchedField,
}) => {
    const [registrationRequest, setRegistrationRequest] = useState<RegistrationRequestProps>({
        isLoading: false,
        errorCode: undefined,
    });

    const { onAction: onError } = useAnalyticsActionTracker('onConfirmIdentiyAuthFailed');

    const handleSubmit = (values: CustomerRegistrationData): void => {
        setRegistrationRequest({ ...registrationRequest, isLoading: true });
        CpDataApi.post(getAccountVerificationRequestEndpoint(), values)
            .then(response => {
                const responseData: CustomerRegistrationResult | CustomerRegistrationMultipleUserResult = response.data;
                onSubmitSuccess(responseData, values);
            })
            .catch(error => {
                const errorCode = parseErrorResponse<CustomerRegistrationError>(error).code;
                setRegistrationRequest({
                    isLoading: false,
                    errorCode,
                });
                onError();
            });
    };

    const { isLoading, errorCode } = registrationRequest;

    return (
        <>
            {isLoading && <Spinner fullPage={true} />}
            <IdentificationForm
                legalNotice={legalNotice}
                onSubmit={handleSubmit}
                errorCode={errorCode}
                setLastTouchedField={setLastTouchedField}
            />
        </>
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { FileUpload } from './file-upload/FileUpload';
import { FormikProps, useFormikContext } from 'formik';
import { AddressWithFiles } from 'common';
import { maxFileSize } from 'config';
import { AddressWithFilesFormSchema } from '../edit-view.types';

export type FileUploadViewProps = {
    previousStep: () => void;
    submit: (values: AddressWithFilesFormSchema) => void;
};

export const FileUploadView: React.FC<FileUploadViewProps> = ({ previousStep, submit }) => {
    const { t } = useTranslation('my-profile-address-details');
    const { values }: { values: AddressWithFiles } = useFormikContext();
    const formik: FormikProps<AddressWithFiles> = useFormikContext();
    const translationPrefix = 'edit-view.file-upload-view';
    const validFileFormats = ['pdf', 'bmp', 'gif', 'jpeg', 'jpg', 'png', 'tif', 'tiff', 'docx', 'doc'];

    const trackingSection = 'Address';
    const { onAction: onFilesUploadFailed } = useAnalyticsActionTracker('onEditProfileDocumentUploadFailed');

    const isSizeValid = (size: number): boolean => !(size > maxFileSize);

    const isTypeValid = (fileType: string): boolean => validFileFormats.some(type => type === fileType);

    const getFileType = (name: string): string => {
        const lastDot = name.lastIndexOf('.');
        const result = name.substring(lastDot + 1).toLowerCase() || '';
        return result;
    };

    const trackFileUploadErrors = (allSizesValid: boolean, allTypesValid: boolean, requiredField: boolean): void => {
        const errorMessage = [
            !allSizesValid && 'invalid size',
            !allTypesValid && 'invalid type',
            !requiredField && 'file not uploaded',
        ]
            .filter(Boolean)
            .join(', ');
        onFilesUploadFailed(trackingSection, { errorMessage });
    };

    const validate = (): void => {
        formik.validateForm().then(validationErrors => {
            const filesList: File[] = formik.values.files;
            const allSizesValid = filesList.every(file => isSizeValid(file.size));
            const allTypesValid = filesList.every(file => isTypeValid(getFileType(file.name)));
            const requiredField = filesList.length;
            if (!Object.keys(validationErrors).length && requiredField && allSizesValid && allTypesValid) {
                submit(values);
            } else {
                trackFileUploadErrors(allSizesValid, allTypesValid, !!requiredField);
            }
        });
    };

    return (
        <>
            <p dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.description_par_1`) }} />
            <Notification
                status={NotificationStatus.info}
                headline={t(`${translationPrefix}.warning-headline`)}
                text={t(`${translationPrefix}.warning-text`)}
            />
            <p
                dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.description_par_2`) }}
                className={'u-mt-small'}
            />
            <FileUpload maxFileSize={maxFileSize} validFileFormats={validFileFormats} />
            <ButtonContainer center>
                <Button secondary onClick={previousStep} testId="edit-address-back" type="button">
                    {t('translation:editable-section-nav.back')}
                </Button>
                <Button onClick={validate} testId="edit-address-submit">
                    {t('translation:editable-section-nav.submit')}
                </Button>
            </ButtonContainer>
        </>
    );
};

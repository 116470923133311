import React, { useState } from 'react';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-6/frontend-ui';
import { FinancialDetails } from 'common';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import { changePaymentDayPath } from '../../../navigation/paths';
import { EditStatus } from './EditStatus';
import { ConsultView } from './consult-view/ConsultView';
import { EditView } from './edit-view/EditView';

export type PaymentDaySectionProps = {
    details: FinancialDetails;
    encryptedContractNumber: string;
};

export const PaymentDaySection: React.FC<PaymentDaySectionProps> = ({ details, encryptedContractNumber }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    const { onAction: onEdit } = useAnalyticsActionTracker('financialDetailsEditDueDateSection');
    const { onAction: onCancel } = useAnalyticsActionTracker('financialDetailsEditDueDateSectionCancel');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'financialDetailsEditDueDateSectionSubmitFailed',
        confirmSuccess: 'financialDetailsEditDueDateSectionSuccess',
    });

    const startEditing = (): void => {
        onEdit();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changePaymentDayPath(encryptedContractNumber));
    };
    const cancelEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        onCancel();
        history.push(path);
    };
    const finishEditing = (newEditStatus: EditStatus): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();
        setLastEditStatus(newEditStatus);
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changePaymentDayPath(encryptedContractNumber)}>
                <EditView details={details} cancelEditing={cancelEditing} finishEditing={finishEditing} />
            </Route>

            <Route path={path}>
                <ConsultView details={details} startEditing={startEditing} lastEditStatus={lastEditStatus} />
            </Route>
        </Switch>
    );
};

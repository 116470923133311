import React from 'react';
import { FormikProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { AddressWithFiles, counties as defaultCounties } from 'common';
import { AddressList } from './AddressList';
import { AddressFormSchema } from '../edit-view.types';
import { getAddressValidationEndpoint } from '@cp-shared-6/apis';
import { CpDataApi } from '../../../../../cp-xhr';

export type FormViewProps = {
    cancelEditing: () => void;
    nextStep: () => void;
};

export const FormView: React.FC<FormViewProps> = ({ cancelEditing, nextStep }) => {
    const { t } = useTranslation('my-profile-address-details');
    const formik: FormikProps<AddressWithFiles> = useFormikContext();
    const getCountyNameByCode = (code?: string): string | undefined =>
        defaultCounties.find(({ countyCode }) => String(countyCode) === code)?.label;

    const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileAddressValidationError');

    const validateAddress = (query: string | undefined): Promise<boolean> => {
        return CpDataApi.get(getAddressValidationEndpoint(), { params: { countryCode: 'IE', query } })
            .then(({ data }) => {
                return !!(data && data?.address && data?.address.city);
            })
            .catch(() => {
                return false;
            });
    };

    const translationPrefix = 'edit-view.form-view.validation-errors';

    const validate = (): void => {
        const addressData: AddressFormSchema = formik.values;
        formik.validateForm().then(validationErrors => {
            if (Object.keys(validationErrors).length === 0) {
                nextStep();
            } else {
                onValidationError(Object.keys(validationErrors).join(', '));
            }
            Object.keys(validationErrors).forEach(fieldName => {
                formik.setFieldTouched(fieldName, true, false);
            });
        });
        if (addressData && addressData.address && addressData.address.addressLine1) {
            setTimeout(() => {
                validateAddress(addressData.address?.addressLine1).then(validated => {
                    if (!validated) {
                        formik.setFieldError(
                            'address.addressLine1',
                            t(`${translationPrefix}.validation-service-error`),
                        );
                    }
                    return true;
                });
            }, 1000);
        }

        if (addressData && addressData.postcode) {
            setTimeout(() => {
                validateAddress(addressData.postcode).then(validated => {
                    if (!validated) {
                        formik.setFieldError('postcode', t(`${translationPrefix}.validation-service-error`));
                    }
                    return true;
                });
            }, 1000);
        }

        if (addressData && addressData.townOrCity) {
            setTimeout(() => {
                validateAddress(addressData.townOrCity).then(validated => {
                    if (!validated) {
                        formik.setFieldError('townOrCity', t(`${translationPrefix}.validation-service-error`));
                    }
                    return true;
                });
            }, 1000);
        }

        if (addressData && addressData.countyCode) {
            setTimeout(() => {
                const county = getCountyNameByCode(addressData.countyCode);
                validateAddress(county).then(validated => {
                    if (!validated) {
                        formik.setFieldError('countyCode', t(`${translationPrefix}.validation-service-error`));
                    }
                    return true;
                });
            }, 1000);
        }
    };

    return (
        <>
            <Notification status={NotificationStatus.warning} text={t('edit-view.form-view.warning-info')} />
            <AddressList />
            <ButtonContainer center>
                <Button secondary onClick={cancelEditing} testId="edit-address-cancel" type="button">
                    {t('translation:editable-section-nav.cancel')}
                </Button>
                <Button onClick={validate} testId="edit-address-next">
                    {t('translation:editable-section-nav.next')}
                </Button>
            </ButtonContainer>
        </>
    );
};

import React, { useState } from 'react';
import base64ToBlob from 'b64-to-blob';
import { Spinner, DownloadLink as DownloadLinkShared, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { saveAs as downloadFileAs } from 'file-saver';
import { CpDataApi } from '../../../../../../../cp-xhr';
import { SepaMandateForDownload } from 'common';
import { Modal, ErrorMessage } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export type DownloadLinkProps = {
    showError: boolean;
    setIsDownloaded: (value: boolean) => void;
    sepaDownloadEndpoint: string;
    iban: string;
    allContracts: boolean;
    onClick: () => void;
};

export const DownloadLink: React.FC<DownloadLinkProps> = ({
    showError,
    setIsDownloaded,
    sepaDownloadEndpoint,
    iban,
    allContracts,
    onClick,
}) => {
    const { t } = useTranslation('financial-details');
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState(false);
    const { onAction: trackDownload } = useAnalyticsActionTracker('onSepaMandateDownload');
    const { onAction: trackDownloadError } = useAnalyticsActionTracker('sepaMandateDownloadError');

    const downloadFile = (): void => {
        onClick();
        setIsDownloading(true);
        CpDataApi.get(sepaDownloadEndpoint, { params: { iban, allContracts } })
            .then(response => {
                const { fileName, fileContent }: SepaMandateForDownload = response.data;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                downloadFileAs(pdfBlob, fileName);
                setIsDownloaded(true);
                setIsDownloading(false);
                trackDownload();
            })
            .catch(error => {
                setDownloadError(true);
                setIsDownloading(false);
                trackDownloadError(error);
            });
    };

    const sepaDownloadPrefix = 'bank-account-section.iban.edit-view.sepa-download-view';

    return isDownloading ? (
        <Spinner small />
    ) : (
        <>
            <DownloadLinkShared
                label={t(`${sepaDownloadPrefix}.link`)}
                onClick={downloadFile}
                testId={'sepa-download-link'}
            />
            {showError && <ErrorMessage>{t(`${sepaDownloadPrefix}.error.sepa-not-downloaded`)}</ErrorMessage>}
            <Modal
                shown={downloadError}
                status="error"
                onClose={(): void => setDownloadError(false)}
                testId={'sepa-error-modal'}
            >
                <p
                    dangerouslySetInnerHTML={{
                        __html: t(`${sepaDownloadPrefix}.error.download-fails`),
                    }}
                />
            </Modal>
        </>
    );
};

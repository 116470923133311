import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Fieldset, Form } from '@vwfs-bronson/bronson-react';
import { Formik, FormikProps, FormikValues } from 'formik';
import { FinancialDetails } from 'common';
import {
    DataOverview,
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
} from '@cp-shared-6/frontend-ui';
import { EditStatus } from '../EditStatus';
import { MonthlyPaymentDaySelection } from './MonthlyPaymentDaySelection/MonthlyPaymentDaySelection';
import { CpDataApi } from '../../../../../cp-xhr';
import { NoConnectionView } from '../no-connection-view/NoConnectionView';

type PaymentDayFormDataType = {
    monthlyPaymentDay: string;
};

export type EditViewProps = {
    details?: FinancialDetails;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus) => void;
};

export const EditView: React.FC<EditViewProps> = ({ details, cancelEditing, finishEditing }) => {
    const { t } = useTranslation('financial-details');
    const translationPrefix = 'payment-day-section.edit-view';
    const [isSubmitting, setIsSubmitting] = useState(false);

    const initialValues: PaymentDayFormDataType = {
        monthlyPaymentDay: (details?.monthlyPaymentDay || 1).toString(),
    };

    const onSubmit = (values: PaymentDayFormDataType): void => {
        if (!details?._links?.changePaymentDay) {
            return;
        }
        if (values.monthlyPaymentDay === initialValues.monthlyPaymentDay) {
            finishEditing(EditStatus.NOT_CHANGED);
            return;
        }
        setIsSubmitting(true);
        CpDataApi.put(details._links?.changePaymentDay, { monthlyPaymentDay: +values.monthlyPaymentDay })
            .then(() => {
                setIsSubmitting(false);
                finishEditing(EditStatus.SUCCESS);
            })
            .catch(() => {
                setIsSubmitting(false);
                finishEditing(EditStatus.ERROR);
            });
    };

    return (
        <DataOverview title={t(`${translationPrefix}.headline`)} withoutCardEffect={true}>
            {!details ? (
                <NoConnectionView cancelEditing={cancelEditing} />
            ) : (
                <UiBlockingSpinner isBlocking={isSubmitting}>
                    <Formik initialValues={initialValues} onSubmit={onSubmit}>
                        {(formik: FormikProps<FormikValues>): JSX.Element => (
                            <Form data-testid="payment-day-form" onSubmit={preventSubmit()}>
                                <Fieldset>
                                    <Fieldset.Row>
                                        <p>{t(`${translationPrefix}.description`)}</p>
                                        <MonthlyPaymentDaySelection
                                            name={'monthlyPaymentDay'}
                                            testId={'monthly-payment-day-selection'}
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <Notification
                                            status={NotificationStatus.warning}
                                            testId={'success-notification'}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: t(`${translationPrefix}.warning-info.text`),
                                                }}
                                            />
                                        </Notification>
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ButtonContainer center>
                                            <Button
                                                secondary
                                                onClick={cancelEditing}
                                                testId="edit-payment-day-cancel"
                                                type="button"
                                            >
                                                {t('translation:editable-section-nav.cancel')}
                                            </Button>
                                            <Button onClick={formik.submitForm} testId="edit-payment-day-submit">
                                                {t('translation:editable-section-nav.submit')}
                                            </Button>
                                        </ButtonContainer>
                                    </Fieldset.Row>
                                </Fieldset>
                            </Form>
                        )}
                    </Formik>
                </UiBlockingSpinner>
            )}
        </DataOverview>
    );
};

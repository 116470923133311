import { Brand, Contract, ContractDataWithLinks, formatAsDate } from 'common';
import { HeroImage, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { Button, ButtonContainer, Checkbox, ContentSection, Heading, Wizard } from '@vwfs-bronson/bronson-react';
import { redirectToDigitalRenewal } from 'components/contracts';
import { dashboardPagePath } from 'components/navigation/paths';
import { contractsWithHiddenHookPageLocalStorageKey } from 'config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { WizardWithoutAccordion } from '../wizard-without-accordion';

type DigitalRenewalUiProps = {
    contract?: Contract | ContractDataWithLinks;
    name?: string;
};

export const DigitalRenewalUi: React.FC<DigitalRenewalUiProps> = ({ contract, name }) => {
    const { t } = useTranslation('digital-renewal');
    const history = useHistory();
    const { onAction: onDigitalRenewalStart } = useAnalyticsActionTracker('onDigitalRenewalStart');

    const onSecondaryClick = () => history.push(dashboardPagePath());

    const onPrimaryClick = (brand?: Brand): void => {
        redirectToDigitalRenewal(brand);
        onDigitalRenewalStart('Digital renewal invitation');
        history.push(dashboardPagePath());
    };

    const onCheckboxChange = (e: { target: { checked: boolean } }) => {
        const contractsWithHiddenHookPage =
            window.localStorage.getItem(contractsWithHiddenHookPageLocalStorageKey)?.split(',') || [];

        if (e.target.checked) {
            contractsWithHiddenHookPage?.push(contract?.contractNumber || '');

            window.localStorage.setItem(contractsWithHiddenHookPageLocalStorageKey, contractsWithHiddenHookPage.join());
        } else {
            contractsWithHiddenHookPage.pop();

            window.localStorage.setItem(contractsWithHiddenHookPageLocalStorageKey, contractsWithHiddenHookPage.join());
        }
    };

    const dealerOrRetailer = contract?.brand === Brand.AUDI || contract?.brand === Brand.SKODA ? 'Dealer' : 'Retailer';

    const wizardItems = [
        {
            heading: t('stepper.first-item.heading'),
            content: t('stepper.first-item.content'),
        },
        {
            heading: t('stepper.second-item.heading'),
            content: t('stepper.second-item.content'),
        },
        {
            heading: t('stepper.third-item.heading'),
            content: t('stepper.third-item.content', { brand: contract?.brand, dealerOrRetailer }),
        },
    ];

    return (
        <>
            <HeroImage
                title={t('hero.title')}
                imageUrl={'/contents/vwfs/digital-renewal-image.jpg' || ''}
                subTitle={t('hero.subTitle')}
                inverted={false}
                shallow={true}
            />
            <ContentSection altBackground pageWrapSize="medium">
                <p
                    className={'u-mb'}
                    dangerouslySetInnerHTML={{
                        __html: t('paragraph', {
                            firstName: name,
                            currentModel: contract?.model,
                            contrctNumber: contract?.contractNumber,
                            endDate: formatAsDate(contract?.endDate),
                            brand: contract?.brand,
                        }),
                    }}
                />
            </ContentSection>
            <Heading className={'u-text-center u-mt'} level={2}>
                {t('stepper.heading')}
            </Heading>
            <WizardWithoutAccordion collapseBreakpoint={'s'}>
                {wizardItems.map((v, index) => (
                    <Wizard.Item key={index}>
                        <div className={'u-mt-small'}>{v.content}</div>
                    </Wizard.Item>
                ))}
            </WizardWithoutAccordion>
            <ButtonContainer center className={'u-mt-large'}>
                <Button secondary onClick={onSecondaryClick}>
                    {t('button.secondary')}
                </Button>
                <Button onClick={() => onPrimaryClick(contract?.brand)}>{t('button.primary')}</Button>
            </ButtonContainer>
            <div className={'u-mv u-text-center'}>
                <Checkbox onChange={onCheckboxChange}>{t('checkbox')}</Checkbox>
            </div>
        </>
    );
};

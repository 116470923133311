import { Base64File, FinancialDetails, Iban, IbanWithBase64Files, IbanWithFiles } from 'common';
import { BankData, SignedData } from '@cp-shared-6/apis';
import { CpDataApi } from 'cp-xhr';
import React, { useState } from 'react';
import { maxFileSize } from '../../../../../config';
import { getBase64 } from '../../../../../utils';
import { useScrollTo } from '../../../../../utils/use-scroll-to';
import { groupByFileSize } from '../../../../file-upload/utils';
import { EditStatus } from './EditStatus';
import { FileUploadView } from './file-upload-view';
import { IbanValidationView } from './iban-validation-view';
import { SepaDownloadView } from './sepa-download-view';
import { useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';

export type EditViewNames = 'IBAN_VALIDATION_VIEW' | 'SEPA_DOWNLOAD' | 'DOCUMENT_UPLOAD';

export type EditViewProps = {
    details: FinancialDetails;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus) => void;
};

export const EditView: React.FC<EditViewProps> = ({ cancelEditing, finishEditing, details }) => {
    const firstView = 'IBAN_VALIDATION_VIEW';
    const [isSubmitError, setSubmitError] = useState<boolean>(false);
    const [currentView, setCurrentView] = useState<EditViewNames>(firstView);
    const [validatedIban, setValidatedIban] = useState<Iban>({
        signedBankData: {
            data: {
                isValid: false,
            },
            checksum: '',
        },
        allContracts: false,
    });
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const scrollToRef = useScrollTo(currentView);

    const { onAction: onSepaDigitalSignSuccess } = useAnalyticsActionTracker('onDashboardEditSepaSignSuccess');
    const { onAction: onSepaDigitalSignError } = useAnalyticsActionTracker('onDashboardEditSepaSignError');

    if (!details._links?.changeIban || !details._links?.downloadSepa) {
        return null;
    }

    const toNextView = (): void => {
        switch (currentView) {
            case 'IBAN_VALIDATION_VIEW': {
                setCurrentView('SEPA_DOWNLOAD');
                break;
            }
            case 'SEPA_DOWNLOAD': {
                setCurrentView('DOCUMENT_UPLOAD');
                break;
            }
            case 'DOCUMENT_UPLOAD': {
                finishEditing(lastEditStatus);
                break;
            }
        }
    };

    const toPreviousView = (): void => {
        switch (currentView) {
            case 'IBAN_VALIDATION_VIEW': {
                cancelEditing();
                break;
            }
            case 'SEPA_DOWNLOAD': {
                setCurrentView('IBAN_VALIDATION_VIEW');
                break;
            }
            case 'DOCUMENT_UPLOAD': {
                setCurrentView('SEPA_DOWNLOAD');
                break;
            }
        }
    };

    const onValidIban = (validatedIban: Iban): void => {
        setValidatedIban(validatedIban);
        toNextView();
    };

    const onChangedIban = (changeStatus: EditStatus): void => {
        setLastEditStatus(changeStatus);
        toNextView();
    };

    const submit = (iban: Iban, files: File[], endpoint: string): Promise<void> => {
        const filePromises: Promise<Base64File>[] = files.map(file => getBase64(file));
        return Promise.all(filePromises).then(files => {
            const data: IbanWithBase64Files = { iban, files };
            return CpDataApi.put(endpoint, data);
        });
    };

    function mapToIban(signedBankData: SignedData<BankData>, allContracts: boolean): Iban {
        return { signedBankData, allContracts };
    }

    const onSubmit = (values: IbanWithFiles): void => {
        const { ibanProof, sepaUpload, signedBankData, allContracts } = values;
        const promises: Promise<void>[] = [];
        setIsSubmitting(true);
        if (!details._links?.changeIban) {
            setIsSubmitting(false);
            onChangedIban(EditStatus.ERROR);
            return;
        }
        const changeIbanEndpoint = details._links.changeIban;
        const portions: Array<File[]> = groupByFileSize([...sepaUpload, ...ibanProof], maxFileSize);

        portions.forEach((files: File[]) => {
            promises.push(submit(mapToIban(signedBankData, allContracts), files, changeIbanEndpoint));
        });

        Promise.all(promises)
            .then(() => {
                setIsSubmitting(false);
                onSepaDigitalSignSuccess();
                onChangedIban(EditStatus.SUCCESS);
                finishEditing(EditStatus.SUCCESS);
                setSubmitError(false);
            })
            .catch(() => {
                setIsSubmitting(false);
                onSepaDigitalSignError();
                setSubmitError(true);
            });
    };

    return (
        <section ref={scrollToRef}>
            {currentView === 'IBAN_VALIDATION_VIEW' && (
                <IbanValidationView
                    previousIban={details.iban}
                    onValidIban={onValidIban}
                    toPreviousView={toPreviousView}
                    isFirstView={true}
                />
            )}
            {validatedIban?.signedBankData?.data?.bankDetails?.iban && currentView === 'SEPA_DOWNLOAD' && (
                <SepaDownloadView
                    sepaDownloadEndpoint={details._links?.downloadSepa || ''}
                    digitalSignEndpoint={details._links?.digitalSign || ''}
                    iban={validatedIban.signedBankData.data.bankDetails.iban}
                    toPreviousView={toPreviousView}
                    toNextView={toNextView}
                    allContracts={validatedIban.allContracts}
                />
            )}
            {currentView === 'DOCUMENT_UPLOAD' && (
                <FileUploadView
                    toPreviousView={toPreviousView}
                    submit={onSubmit}
                    isSubmitting={isSubmitting}
                    iban={validatedIban}
                    isSubmitError={isSubmitError}
                />
            )}
        </section>
    );
};

import React from 'react';
import { ThirdPartyLicenses, License } from 'common';
import { HeroImage, ThirdPartyLicensesTable, getLicenseKey, groupLicensesByType } from '@cp-shared-6/frontend-ui';
import { ContentSection, PageWrap } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export const ThirdPartyLicensesUi: React.FC<{ thirdPartyLicenses?: ThirdPartyLicenses }> = ({ thirdPartyLicenses }) => {
    const { t } = useTranslation('third-party-licenses');
    if (!thirdPartyLicenses) {
        return null;
    }

    const { teaser, content } = thirdPartyLicenses || {};

    const columnHeadings: string[] = [t('columns.name'), t('columns.version'), t('columns.copyright')];

    const getDescriptionAndLink = (
        licenseType: string,
    ): { description: string; link: { label: string; url: string }; textAboveTable: string } => {
        const key = getLicenseKey(licenseType);
        return {
            description: t(`licenses.${key}.description`),
            link: { label: t(`licenses.${key}.headline`), url: t(`licenses.${key}.link`) },
            textAboveTable: t(`licenses.${key}.headline`),
        };
    };

    return (
        <>
            <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} subTitle={teaser.subTitle} shallow={false} />
            <ContentSection>
                <PageWrap size={'medium'}>
                    {Object.entries(groupLicensesByType<License>(content)).map(([licenseType, licenses]) => (
                        <ThirdPartyLicensesTable
                            key={licenseType}
                            columnHeadings={columnHeadings}
                            viewLicenseLabel={t(`view-license`)}
                            rows={licenses}
                            {...getDescriptionAndLink(licenseType)}
                        />
                    ))}
                </PageWrap>
            </ContentSection>
        </>
    );
};

import React from 'react';
import { Faq } from 'common';
import { Faq as FaqShared, HeroImage, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';

export const FaqUi: React.FC<{ faq?: Faq; isLoggedIn: boolean }> = ({ faq, isLoggedIn }) => {
    const { onAction } = useAnalyticsActionTracker('onFaqQuestion');
    if (!faq) {
        return null;
    }
    const { teaser, content } = faq || {};
    const [privateContent, publicContent] = content || [];
    const contentComponent = isLoggedIn ? privateContent : publicContent;

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} />
    ) : null;

    let openItems = [];
    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        onAction(refs[refs.length - 1]);
    };

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">
                <FaqShared onChange={handleOnChange} openOneAtTime={false} {...contentComponent} />
            </ContentSection>
        </>
    );
};

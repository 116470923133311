import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { ThirdPartyLicensesUi } from './ui';
import { useThirdPartyLicenses } from './useThirdPartyLicenses';
import { License, ThirdPartyLicenses } from 'common';
import content from './licenses-page-content.json';
import { StaticPageLoadingPlaceholder } from '@cp-shared-6/frontend-ui';

const ThirdPartyLicensesWithHandlers = withLoadingAndNoConnectionHandler(ThirdPartyLicensesUi);

export const ThirdPartyLicensesView: React.FC = () => {
    const { cmsContent: thirdPartyLicenses, isLoading, loadingError } = useThirdPartyLicenses();

    if (!thirdPartyLicenses) {
        return null;
    }

    const licensesWithContent: ThirdPartyLicenses = {
        teaser: thirdPartyLicenses.teaser,
        content: content as License[],
    };
    return (
        <ThirdPartyLicensesWithHandlers
            isLoading={isLoading}
            thirdPartyLicenses={licensesWithContent}
            hasError={!!loadingError}
            loadingPlacehloder={<StaticPageLoadingPlaceholder />}
        />
    );
};

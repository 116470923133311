import { Brand } from 'common';
import { Button, ButtonContainer, Heading, Layout, TileSelect, TileSelectGroup } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../../navigation/paths';
import { ContractEndOptionsView } from '../enums';
import { useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';

enum OptionsView {
    MAIN = 'MAIN',
    REFINANCE = 'REFINANCE',
}
export enum Option {
    RENEWAL = 'RENEWAL',
    REFINANCE = 'REFINANCE',
    REFINANCING = 'REFINANCING',
    PAYMENT = 'PAYMENT',
    CAR_RETURN = 'CAR_RETURN',
}
type Tile = {
    value: Option;
    icon: string;
    title: string;
    description: string;
    changeCurrentView?: OptionsView;
};

export const OptionsOverview: React.FC<{
    handleSubmit: (contractEndOptionsView: ContractEndOptionsView) => void;
    brand?: Brand;
}> = ({ handleSubmit, brand }) => {
    const { t } = useTranslation('contract-end-options');
    const history = useHistory();

    const [selectedOption, setSelectedOption] = useState<Option | undefined>();
    const [currentView, setCurrentView] = useState(OptionsView.MAIN);

    const { onAction: onRenewalSelected } = useAnalyticsActionTracker('onEndOfContractPcpRenewalSelected');
    const { onAction: onDealerData } = useAnalyticsActionTracker('onEndOfContractPcpDealerData');

    const optionsTranslationPrefix = 'options-overview.options';
    const subOptionsTranslationPrefix = `${optionsTranslationPrefix}.refinance.sub-options`;

    const showRenewalTile = brand === Brand.VW || brand === Brand.AUDI || brand === Brand.SEAT || brand === Brand.SKODA;

    const renewalTile: Tile[] = showRenewalTile
        ? [
              {
                  value: Option.RENEWAL,
                  icon: 'semantic-car',
                  title: t(`${optionsTranslationPrefix}.renewal.title`),
                  description: t(`${optionsTranslationPrefix}.renewal.description`),
              },
          ]
        : [];

    const mainViewTiles: Tile[] = [
        ...renewalTile,
        {
            value: Option.REFINANCE,
            icon: 'semantic-accessoires',
            title: t(`${optionsTranslationPrefix}.refinance.title`),
            description: t(`${optionsTranslationPrefix}.refinance.description`),
            changeCurrentView: OptionsView.REFINANCE,
        },
        {
            value: Option.CAR_RETURN,
            icon: 'semantic-reload',
            title: t(`${optionsTranslationPrefix}.car-return.title`),
            description: t(`${optionsTranslationPrefix}.car-return.description`),
        },
    ];

    const refinanceViewTiles: Tile[] = [
        {
            value: Option.REFINANCING,
            icon: 'semantic-calculator',
            title: t(`${subOptionsTranslationPrefix}.refinancing.title`),
            description: t(`${subOptionsTranslationPrefix}.refinancing.description`),
        },
        {
            value: Option.PAYMENT,
            icon: 'semantic-fs-payment',
            title: t(`${subOptionsTranslationPrefix}.payment.title`),
            description: t(`${subOptionsTranslationPrefix}.payment.description`),
        },
    ];

    const resetSelectedOption = (): void => setSelectedOption(undefined);

    const handleBackClick = (): void => {
        if (currentView === OptionsView.MAIN) {
            history.push(dashboardPagePath());
        }
        resetSelectedOption();
        setCurrentView(OptionsView.MAIN);
    };

    const getRenewalExternalLink = (): string => {
        const renewalLinkPrefix = `${optionsTranslationPrefix}.renewal.links`;
        let externalLink = '';
        switch (brand) {
            case Brand.VW:
                externalLink = t(`${renewalLinkPrefix}.vw`);
                break;
            case Brand.AUDI:
                externalLink = t(`${renewalLinkPrefix}.audi`);
                break;
            case Brand.SEAT:
                externalLink = t(`${renewalLinkPrefix}.seat`);
                break;
            case Brand.SKODA:
                externalLink = t(`${renewalLinkPrefix}.skoda`);
                break;
        }
        return externalLink;
    };

    const handleSubmitClick = (): void => {
        if (selectedOption === Option.REFINANCING) onDealerData('Refinancing');
        if (selectedOption === Option.PAYMENT) onDealerData('Payment');
        if (selectedOption === Option.CAR_RETURN) onDealerData('Return the car');

        if (selectedOption === Option.RENEWAL) {
            onRenewalSelected();
            window.open(getRenewalExternalLink(), '_blank');
            history.push(dashboardPagePath());
        } else {
            handleSubmit(ContractEndOptionsView.DEALER_INFORMATION);
        }
    };

    const getTiles = (tiles: Tile[]): React.ReactElement[] =>
        tiles.map(({ value, title, description, icon, changeCurrentView }) => (
            <TileSelect
                key={value}
                inputType="radio"
                radioGroupName={currentView}
                onClick={(): void => {
                    if (changeCurrentView) {
                        setCurrentView(changeCurrentView);
                        resetSelectedOption();
                    } else {
                        setSelectedOption(value);
                    }
                }}
                value={value}
                title={title}
                icon={icon}
                testId={value}
            >
                {description}
            </TileSelect>
        ));

    return (
        <Layout>
            <Layout.Item>
                <Heading className="u-mt" level={3}>
                    {t('options-overview.sub-headline')}
                </Heading>
                <p>
                    {currentView === OptionsView.MAIN
                        ? t('options-overview.description', { optionsCount: 'three' })
                        : t('options-overview.description', { optionsCount: 'two' })}
                </p>
            </Layout.Item>
            <Layout.Item>
                {currentView === OptionsView.MAIN ? (
                    <TileSelectGroup
                        key={OptionsView.MAIN}
                        layoutItemClassName={`u-1/${showRenewalTile ? 3 : 2} u-1/2@m u-1/1@s`}
                        className={'u-text-center'}
                        testId={'tile-select-group-main'}
                    >
                        {getTiles(mainViewTiles)}
                    </TileSelectGroup>
                ) : (
                    <TileSelectGroup
                        key={OptionsView.REFINANCE}
                        layoutItemClassName="u-1/2 u-1/2@l u-1/1@s"
                        className={'u-text-center'}
                        testId={'tile-select-group-refinance'}
                    >
                        {getTiles(refinanceViewTiles)}
                    </TileSelectGroup>
                )}
            </Layout.Item>
            <Layout.Item>
                <ButtonContainer center className={'u-mt'}>
                    <Button secondary testId="backButton" onClick={handleBackClick}>
                        {t('translation:editable-section-nav.back')}
                    </Button>
                    <Button testId="submitButton" disabled={!selectedOption} onClick={handleSubmitClick}>
                        {t('translation:editable-section-nav.submit')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </Layout>
    );
};

import React, { useState } from 'react';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-6/frontend-ui';
import { Address } from 'common';
import { ConsultView } from './consult-view/ConsultView';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { EditView } from './edit-view/EditView';
import { changeAddressDetailsPath } from '../../navigation/paths';
import { EditStatus } from './EditStatus';
import { useScrollToView } from '../../../utils/use-scroll-to';

export const AddressSection: React.FC<{ address?: Address }> = ({ address }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const { scrollToRef, setCurrentView } = useScrollToView(changeAddressDetailsPath());
    const { onAction: onEdit } = useAnalyticsActionTracker('onEditProfileAddress');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileAddressSubmitFailed',
        confirmSuccess: 'onEditProfileAddressSuccess',
    });

    if (!address) {
        return null;
    }

    const startEditing = (): void => {
        onEdit();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        setCurrentView('EDIT_VIEW');
        history.push(changeAddressDetailsPath());
    };
    const cancelEditing = (): void => {
        onCancel('Address');
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        setCurrentView('CONSULT_VIEW');
        history.push(path);
    };
    const finishEditing = (newEditStatus: EditStatus): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();
        setLastEditStatus(newEditStatus);
        setCurrentView('CONSULT_VIEW');
        history.push(path);
    };

    return (
        <section ref={scrollToRef}>
            <Switch>
                <Route exact path={changeAddressDetailsPath()}>
                    <EditView address={address} cancelEditing={cancelEditing} finishEditing={finishEditing} />
                </Route>
                <Route path={path}>
                    <ConsultView address={address} startEditing={startEditing} lastEditStatus={lastEditStatus} />
                </Route>
            </Switch>
        </section>
    );
};

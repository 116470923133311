import React, { useMemo, useCallback } from 'react';
import { FormSectionGroup, ExpandableContent } from '@vwfs-bronson/bronson-react';
import { ContractDataWithLinks } from 'common';
import { FinancialContract } from '../financial-contract';
import { useTranslation } from 'react-i18next';
import { ErrorNotification, ErrorNotificationProps } from '../../notifications/error/ErrorNotification';
import { groupContractsByVin } from '../../../utils';
import { VehicleDetailsAndImage } from '../vehicle-details-and-image/VehicleDetailsAndImage';

export const ContractsUi: React.FC<{ contracts?: ContractDataWithLinks[] }> = ({ contracts }) => {
    const { t } = useTranslation('contracts');
    const onlyValidContractsList = useMemo(
        () =>
            (contracts || []).filter(
                ({ brand, contractNumber, contractType, isActive }) =>
                    (isActive && brand && contractNumber && contractType) ||
                    (!isActive && contractNumber && contractType),
            ),
        [contracts],
    );

    const areAllContractsValid = useMemo(() => contracts?.length === onlyValidContractsList.length, [
        contracts,
        onlyValidContractsList.length,
    ]);

    const getListOfContracts = useCallback(
        (isActive: boolean) => {
            const filteredContracts = onlyValidContractsList.filter(
                (contract: ContractDataWithLinks) => contract.isActive === isActive,
            );

            const groupedContractsByVin = groupContractsByVin(filteredContracts);

            return groupedContractsByVin.length ? (
                <FormSectionGroup>
                    {groupedContractsByVin.map((contracts, groupIndex) =>
                        contracts.map((contract, contractIndex) => (
                            <React.Fragment key={contract.contractNumber}>
                                {contractIndex === 0 && (
                                    <VehicleDetailsAndImage
                                        vin={contract.vin}
                                        encryptedVin={contract.encryptedVin}
                                        brand={contract.brand}
                                        model={contract.model}
                                        licensePlate={contract.licensePlate}
                                        key={`${contract.contractNumber}-details`}
                                    />
                                )}
                                <FinancialContract
                                    contract={contract}
                                    key={contract.contractNumber}
                                    defaultExpanded={isActive && contractIndex === 0 && groupIndex === 0}
                                />
                            </React.Fragment>
                        )),
                    )}
                </FormSectionGroup>
            ) : null;
        },
        [onlyValidContractsList],
    );

    const memoizedListOfActiveContracts = useMemo(() => getListOfContracts(true), [getListOfContracts]);
    const memoizedListOfInactiveContracts = useMemo(() => getListOfContracts(false), [getListOfContracts]);

    const errorProps: ErrorNotificationProps = contracts?.length
        ? { testId: 'missing-contracts-fields-error', className: 'u-mb' }
        : {
              testId: 'no-contracts-for-account-error',
              text: t('no-contracts-for-account-error'),
          };

    if (!contracts) {
        return null;
    }

    return (
        <>
            {!areAllContractsValid || !contracts.length ? <ErrorNotification {...errorProps} /> : null}
            {memoizedListOfActiveContracts}
            {!!memoizedListOfInactiveContracts && (
                <ExpandableContent
                    triggerLabel={t('expand.trigger')}
                    noBackground
                    pageWrapClassName={'u-pl-none u-pr-none'}
                    lazyRender={true}
                >
                    <h4 className="u-text-center">{t('expand.headline')}</h4>
                    {memoizedListOfInactiveContracts}
                </ExpandableContent>
            )}
        </>
    );
};

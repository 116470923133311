import { ContractDataWithLinks } from 'common';
import { Card } from '@vwfs-bronson/bronson-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EndOfContractOptionsNotification } from '../notifications';
import { Body } from './body';
import { Header } from './header';
import { shouldExpandContract } from '../notifications/iban-notifications/utils';

export type FinancialContractProps = {
    contract?: ContractDataWithLinks;
    defaultExpanded?: boolean;
};

export type ContractDetailsProp = {
    endDate: string;
    finalPaymentAmount: number;
    finalPaymentDate: string;
    financedAmount: number;
    guaranteedMinimumFutureValue: number;
    iban: string;
    isActive: boolean;
    monthlyPaymentDay: number;
    nextPaymentAmount: number;
    remainingNumberOfPayments: number;
    remainingTerm: number;
    startDate: string;
    term: number;
    totalDeposit: number;
};

export const FinancialContract: React.FC<FinancialContractProps> = ({ contract, defaultExpanded }) => {
    const { pathname } = useLocation();
    const [isExpanded, setIsExpanded] = useState<boolean>(shouldExpandContract(pathname, contract));

    useEffect(() => {
        const shouldExpand = shouldExpandContract(pathname, contract);
        if (shouldExpand) {
            setIsExpanded(true);
        }
    }, [pathname, contract]);

    const contractHeader = useMemo(() => {
        if (!contract || !contract.contractNumber || !contract.encryptedContractNumber) return null;
        return (
            <Header
                isActive={contract?.isActive}
                contractNumber={contract?.contractNumber}
                nextPaymentAmount={contract?.nextPaymentAmount}
                contractType={contract?.contractType}
                paymentDate={contract?.paymentDate}
                outstandingBalance={contract?.outstandingBalance}
                inArrears={contract?.inArrears}
            />
        );
    }, [contract]);

    const contractBody = useMemo(() => {
        if (!contract || !contract.contractNumber || !contract.encryptedContractNumber) return null;
        return (
            <Body
                contractNumber={contract.contractNumber}
                encryptedContractNumber={contract.encryptedContractNumber}
                isActive={contract.isActive}
                contractType={contract.contractType}
                outstandingBalance={contract?.outstandingBalance}
                inArrears={contract?.inArrears}
            />
        );
    }, [contract]);

    if (!contract || !contract.contractNumber || !contract.encryptedContractNumber) {
        return null;
    }

    const { brand, contractNumber, encryptedContractNumber, contractType, isEligibility, endDate } = contract;

    return (
        <Card
            element="article"
            title=""
            expandable
            contentLarge={contractBody}
            className={'u-mb'}
            defaultExpanded={isExpanded || defaultExpanded}
            key={`contract-card${isExpanded ? '-expanded' : ''}`}
        >
            {contractHeader}
            <EndOfContractOptionsNotification
                contractBrand={brand}
                contractEndDate={endDate}
                contractNumber={contractNumber}
                encryptedContractNumber={encryptedContractNumber}
                contractType={contractType}
                isEligibility={isEligibility}
                className={'u-mb-small'}
            />
        </Card>
    );
};

import { IntroductionPageTeaser, getIntroductionPageTeaserEndpoint } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<IntroductionPageTeaser>({
    contentName: 'introductionPageTeaser',
    contentEndpoint: getIntroductionPageTeaserEndpoint,
});

export default reducer;
export const fetchIntroductionPageTeaser = fetchContent;

import React from 'react';
import { useHistory } from 'react-router';
import { Button, Card } from '@vwfs-bronson/bronson-react';
import { openInNewWindow, useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { MarketingCard } from 'common';

type MarketingCardComponentProps = {
    content: MarketingCard;
    buttonUrl: string;
    shouldOpenLinkInNewWindow?: boolean;
    testId?: string;
    minHeight?: number;
};

export const MarketingCardComponent: React.FC<MarketingCardComponentProps> = ({
    content,
    buttonUrl,
    shouldOpenLinkInNewWindow,
    testId,
    minHeight,
}) => {
    const { onAction } = useAnalyticsActionTracker('onLearnMore');
    const history = useHistory();
    const { title, imageUrl, text, buttonLabel } = content;

    const onButtonClick = (): void => {
        if (shouldOpenLinkInNewWindow) openInNewWindow(buttonUrl)();
        else history.push(buttonUrl);
        onAction(title);
    };

    const additionalProps = {
        ...(minHeight ? { style: { minHeight: `${minHeight}px` } } : {}),
    };

    return (
        <Card
            element="article"
            title={title}
            imageSrc={imageUrl}
            footer={true}
            className={'u-mb'}
            testId={testId}
            {...additionalProps}
            buttons={
                <Button
                    link={true}
                    icon="semantic-forward"
                    iconReversed
                    small
                    testId={`${testId}-button`}
                    onClick={onButtonClick}
                >
                    {buttonLabel}
                </Button>
            }
        >
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </Card>
    );
};

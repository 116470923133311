import React, { useState } from 'react';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-6/frontend-ui';
import { Contact } from 'common';
import { ConsultView } from './consult-view/ConsultView';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { changeContactDetailsPath } from '../../navigation/paths';
import { EditView } from './edit-view/EditView';
import { EditStatus } from './EditStatus';
import { useScrollToView } from '../../../utils/use-scroll-to';

export const ContactSection: React.FC<{ contact?: Contact }> = ({ contact }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);
    const { scrollToRef, setCurrentView } = useScrollToView(changeContactDetailsPath());
    const { onAction: onEdit } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileContactSubmitFailed',
        confirmSuccess: 'onEditProfileContactSuccess',
    });
    if (!contact) {
        return null;
    }

    const startEditing = (): void => {
        onEdit();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        setCurrentView('EDIT_VIEW');
        history.push(changeContactDetailsPath());
    };

    const finishEditing = (newEditStatus: EditStatus): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();
        setLastEditStatus(newEditStatus);
        setCurrentView('CONSULT_VIEW');
        history.push(path);
    };

    const cancelEditing = (): void => {
        onCancel('Contact');
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        setCurrentView('CONSULT_VIEW');
        history.push(path);
    };

    return (
        <section ref={scrollToRef}>
            <Switch>
                <Route exact path={changeContactDetailsPath()}>
                    <EditView contact={contact} cancelEditing={cancelEditing} finishEditing={finishEditing} />
                </Route>
                <Route path={path}>
                    <ConsultView contact={contact} startEditing={startEditing} lastEditStatus={lastEditStatus} />
                </Route>
            </Switch>
        </section>
    );
};

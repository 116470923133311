import React from 'react';
import { useTranslation } from 'react-i18next';
import { Contact, Phone } from 'common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-6/frontend-ui';

export const ContactList: React.FC<{ contact: Contact }> = ({ contact }) => {
    const { t } = useTranslation('my-profile-contact-details');
    const { mobilePhone, otherPhone, email } = contact;

    const getFullPhone = (phone?: Phone): string => {
        if (!phone || !phone.number) {
            return '-';
        }
        return phone.countryCode ? `+${phone.countryCode}${phone.number}` : `${phone.number}`;
    };

    const contactList: DefinitionListItem[] = [
        {
            label: t('consult-view.email.label'),
            value: email || '-',
            tooltip: t('consult-view.email.tooltip'),
            testId: 'contact-email',
        },
        {
            label: t('consult-view.mobile-phone.label'),
            value: getFullPhone(mobilePhone),
            tooltip: t('consult-view.mobile-phone.tooltip'),
            testId: 'contact-mobile-phone',
        },
        {
            label: t('consult-view.other-phone'),
            value: getFullPhone(otherPhone),
            testId: 'contact-other-phone',
        },
    ];

    return <DefinitionList split={true} list={contactList} />;
};

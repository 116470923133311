import { getProductRoutingEndpoint, ProductRoutingResponse } from 'common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-6/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { AxiosRequestConfig } from 'axios';

const { reducer, fetchData } = getSimpleDataFetchSlice<ProductRoutingResponse, DefaultBusinessMarketApiErrorCode>({
    dataName: 'productRouting',
    fetchCallback(config?: AxiosRequestConfig) {
        return CpDataApi.post<ProductRoutingResponse>(getProductRoutingEndpoint(), config?.data).then(
            result => result.data,
        );
    },
});

export default reducer;
export const fetchProductRouting = fetchData;

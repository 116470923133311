import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractType, formatAsCurrency, formatAsDate } from 'common';
import { CustomProgress, DescriptionList, InfoIcon, Paragraph } from '@vwfs-bronson/bronson-react';

export type ContractProgressProps = {
    contractType?: ContractType;
    outstandingBalance?: number;
    financedAmount?: number;
    finalPaymentAmount?: number;
    finalPaymentDate?: string;
    startDate?: string;
    guaranteedMinimumFutureValue?: number;
};

export const ContractProgress: React.FC<ContractProgressProps> = ({
    contractType,
    outstandingBalance,
    financedAmount,
    finalPaymentAmount,
    finalPaymentDate,
    startDate,
    guaranteedMinimumFutureValue,
}) => {
    const { t } = useTranslation('financial-contract-progress');

    const finalPaymentDateValue = formatAsDate(finalPaymentDate);
    const startDateValue = formatAsDate(startDate);

    const financedAmountValue = financedAmount ? formatAsCurrency(financedAmount) : '-';
    const finalPaymentAmountValue =
        finalPaymentAmount &&
        (contractType === ContractType.PERSONAL_CONTRACT_PLAN ||
            (guaranteedMinimumFutureValue && guaranteedMinimumFutureValue > 0))
            ? formatAsCurrency(finalPaymentAmount)
            : '';
    const outstandingBalanceValue = outstandingBalance ? formatAsCurrency(outstandingBalance) : '-';
    const finalPaymentAmountOrDateLabel =
        contractType === ContractType.PERSONAL_CONTRACT_PLAN
            ? t('optional-final-payment')
            : guaranteedMinimumFutureValue && guaranteedMinimumFutureValue > 0
            ? t('final-payment')
            : t('final-payment-date');
    const finalPaymentTooltip =
        contractType === ContractType.PERSONAL_CONTRACT_PLAN
            ? t('optional-final-payment-tooltip')
            : guaranteedMinimumFutureValue && guaranteedMinimumFutureValue > 0
            ? t('final-payment-tooltip')
            : null;
    const revertApproach: boolean =
        (outstandingBalance ? outstandingBalance : 0) > (financedAmount ? financedAmount : 0);

    return (
        <>
            <CustomProgress
                connect={[true, false]}
                detailsLeft={
                    <DescriptionList.Group termText={financedAmountValue}>
                        <DescriptionList.Detail>
                            <Paragraph>
                                {t('amount-financed')}
                                <InfoIcon aria-label="amount-finance-tooltip" icon="semantic-info">
                                    {t('amount-financed-tooltip')}
                                </InfoIcon>
                            </Paragraph>{' '}
                        </DescriptionList.Detail>
                        <DescriptionList.Detail>
                            <time dateTime={startDate}>{startDateValue}</time>
                        </DescriptionList.Detail>
                    </DescriptionList.Group>
                }
                detailsRight={
                    <DescriptionList.Group termText={finalPaymentAmountValue}>
                        <DescriptionList.Detail>
                            <Paragraph>
                                {finalPaymentAmountOrDateLabel}{' '}
                                {finalPaymentTooltip && (
                                    <InfoIcon aria-label="final-payment-tooltip" icon="semantic-info">
                                        {finalPaymentTooltip}
                                    </InfoIcon>
                                )}
                            </Paragraph>
                        </DescriptionList.Detail>
                        <DescriptionList.Detail>
                            <time dateTime={finalPaymentDate}>{finalPaymentDateValue}</time>
                        </DescriptionList.Detail>
                    </DescriptionList.Group>
                }
                range={
                    revertApproach
                        ? {
                              min: finalPaymentAmount ? finalPaymentAmount : 100,
                              max: financedAmount ? financedAmount : 0,
                          }
                        : {
                              max: finalPaymentAmount ? finalPaymentAmount : 100,
                              min: financedAmount ? financedAmount : 0,
                          }
                }
                steps={[
                    {
                        tooltip: {
                            content: (
                                <>
                                    {outstandingBalanceValue}
                                    <br /> {t('outstanding')}
                                </>
                            ),
                            placement: 'top',
                        },
                        value: revertApproach ? (outstandingBalance ? -outstandingBalance : 0) : outstandingBalance,
                    },
                ]}
            />
        </>
    );
};

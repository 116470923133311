import { select } from '@storybook/addon-knobs';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { Error } from '@cp-shared-6/common-utilities';

export type MockOptions<TError extends string> = {
    [mockName: string]: {
        status: number;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseBody?: Record<string, any> | string | Error<TError>;
        delay?: number;
    };
};

export type EndpointMockDescription<TError extends string> = {
    name: string;
    url: string;
    method: 'get' | 'put' | 'post';
    requestConfig?: AxiosRequestConfig;
    defaultMockOption?: string;
    mockOptions: MockOptions<TError>;
};

function setupMockResponse<TError extends string>(
    mockApi: MockAdapter,
    endpointMockDescription: EndpointMockDescription<TError>,
): void {
    const defaultMockOption = endpointMockDescription.defaultMockOption
        ? endpointMockDescription.defaultMockOption
        : Object.keys(endpointMockDescription.mockOptions)[0];
    const mockVariant: string = select(
        endpointMockDescription.name,
        Object.keys(endpointMockDescription.mockOptions),
        defaultMockOption,
    );
    const mockConfiguration = endpointMockDescription.mockOptions[mockVariant];

    let mockCall;

    switch (endpointMockDescription.method) {
        case 'get':
            mockCall = mockApi.onGet(endpointMockDescription.url, endpointMockDescription.requestConfig);
            break;
        case 'put':
            mockCall = mockApi.onPut(endpointMockDescription.url, endpointMockDescription.requestConfig);
            break;
        case 'post':
            mockCall = mockApi.onPost(endpointMockDescription.url, endpointMockDescription.requestConfig);
            break;
    }

    mockCall.reply(
        () =>
            new Promise(resolve => {
                setTimeout(() => {
                    resolve([mockConfiguration.status, mockConfiguration.responseBody]);
                }, mockConfiguration.delay || 0);
            }),
    );
}

export function setupMockResponses(
    dataApi: AxiosInstance,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    endpointMockDescriptions: EndpointMockDescription<any> | EndpointMockDescription<any>[],
): void {
    const dataApiMock = new MockAdapter(dataApi);
    dataApiMock.reset();
    if (Array.isArray(endpointMockDescriptions)) {
        endpointMockDescriptions.forEach(endpointMockDescription =>
            setupMockResponse(dataApiMock, endpointMockDescription),
        );
    } else {
        setupMockResponse(dataApiMock, endpointMockDescriptions);
    }
}

import React, { useState } from 'react';
import { DigitalSignRequest, PersonalDetails, ScriveDigitalSignResponse } from 'common';
import { Modal, Spinner } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from '../../../../../../../../cp-xhr';
import { DigitalSignFormValues, FormView } from '../form-view';
import { ScriveRedirectionView } from '../scrive-redirection-view';

export type DigitalSignFormUiProps = {
    personalDetails?: PersonalDetails;
    digitalSignEndpoint: string;
    iban: string;
    allContracts: boolean;
    onCancelClick: () => void;
    onSubmit: () => void;
};

export const DigitalSignFormUi: React.FC<DigitalSignFormUiProps> = ({
    personalDetails,
    digitalSignEndpoint,
    iban,
    allContracts,
    onCancelClick,
    onSubmit,
}) => {
    const { t } = useTranslation('financial-details');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [scriveLink, setScriveLink] = useState<string>();
    const [showError, setShowError] = useState(false);

    const translationPrefix = 'bank-account-section.iban.edit-view.digital-sign';

    const handleSubmit = (values: DigitalSignFormValues): void => {
        setIsSubmitting(true);
        const { bankAccountHolder, email, mobilePhone } = values;
        const body: DigitalSignRequest = {
            bankAccountHolder,
            email,
            iban,
            mobilePhone,
            allContracts: allContracts,
        };
        CpDataApi.post<ScriveDigitalSignResponse>(digitalSignEndpoint, body)
            .then(({ data }) => {
                setScriveLink(data?.link);
                setIsSubmitting(false);
                onSubmit();
            })
            .catch(() => {
                setShowError(true);
                setIsSubmitting(false);
            });
    };

    return (
        <>
            {scriveLink ? (
                <ScriveRedirectionView onCancelClick={onCancelClick} scriveLink={scriveLink} />
            ) : (
                <FormView personalDetails={personalDetails} onSubmit={handleSubmit} onCancelClick={onCancelClick} />
            )}
            <Modal
                shown={showError}
                status="error"
                onClose={(): void => setShowError(false)}
                testId={'digital-sign-modal'}
            >
                <p
                    dangerouslySetInnerHTML={{
                        __html: t(`${translationPrefix}.submission-error`),
                    }}
                />
            </Modal>
            {isSubmitting && (
                <Spinner fullPage>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: t(`${translationPrefix}.wait-message`),
                        }}
                    />
                </Spinner>
            )}
        </>
    );
};

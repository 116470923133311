import { MarketingPreference, MarketingPreferenceType } from 'common';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import { Checkbox, DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorNotification } from '../../../../notifications/error/ErrorNotification';
import { EditStatus } from '../../../contact-section/EditStatus';

type MarketingPreferencesUiSectionProps = {
    marketingPreferenceType: MarketingPreferenceType;
    marketingPreference?: MarketingPreference;
    startEditing: () => void;
    lastEditStatus: EditStatus;
};

export const CommonConsultView: React.FC<MarketingPreferencesUiSectionProps> = ({
    marketingPreferenceType,
    marketingPreference,
    startEditing,
    lastEditStatus,
}) => {
    const { t } = useTranslation('my-profile-marketing-preferences');
    if (!marketingPreference) {
        return null;
    }

    const type = marketingPreferenceType === MarketingPreferenceType.VWFS ? 'vwfs' : 'business-partners';

    const marketingData: Array<{ key: string; value: boolean | undefined }> = [
        { key: 'email', value: marketingPreference.email },
        { key: 'phone', value: marketingPreference.phone },
        { key: 'sms', value: marketingPreference.sms },
        { key: 'post', value: marketingPreference.post },
    ];

    const editButtonProps = { onClick: startEditing, secondary: true, icon: '', link: false, testId: 'edit-button' };

    const notification =
        lastEditStatus === EditStatus.SUCCESS ? (
            <Notification
                status={NotificationStatus.warning}
                headline={t('edit-view.notification-success.headline')}
                text={t('edit-view.notification-success.text')}
                testId={'success-notification'}
                className={'u-mt-small'}
            />
        ) : (
            <ErrorNotification
                text={
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('edit-view.notification-error.text'),
                        }}
                    />
                }
                testId={'error-notification'}
                className={'u-mt-small'}
            />
        );

    return (
        <DataOverview
            title={t(`${type}-title`)}
            buttonLabel={t('translation:editable-section-nav.start')}
            buttonProps={editButtonProps}
        >
            <p>{t(`description.${type}`)}</p>
            {marketingData.map(({ key, value }) => (
                <div className={'u-mb-xsmall'} key={key}>
                    <Checkbox checked={value} disabled>
                        {t(key)}
                    </Checkbox>
                </div>
            ))}
            {lastEditStatus !== EditStatus.NOT_PERFORMED && notification}
        </DataOverview>
    );
};

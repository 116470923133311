import React from 'react';
import { BalanceEnquiryForm } from './balance-enquiry-form';
import { FinancialStatement } from './financial-statement';
import { RefundQuery } from './refund-query';
import { RequestCategory } from 'common';

export type CategoryViewProps = {
    category: RequestCategory;
};

export const CategoryView: React.FC<CategoryViewProps> = ({ category }) => {
    switch (category) {
        case 'BALANCE_ENQUIRY_FORM':
            return <BalanceEnquiryForm />;
        case 'FINANCIAL_STATEMENT':
            return <FinancialStatement />;
        case 'REFUND_QUERY':
            return <RefundQuery />;
        default:
            return null;
    }
};

import React from 'react';
import { PersonalDetails } from 'common';
import { IdentificationSection } from '../identification-section';
import { ContactSection } from '../contact-section';
import { AddressSection } from '../address-section';
import { MarketingPreferences } from '../marketing-preferences-sections';

export const MyProfileUi: React.FC<{ personalDetails?: PersonalDetails }> = ({ personalDetails }) => {
    if (!personalDetails) {
        return null;
    }

    return (
        <>
            <IdentificationSection identification={personalDetails.identification} />
            <AddressSection address={personalDetails.address} />
            <ContactSection contact={personalDetails.contactDetails} />
            <MarketingPreferences />
        </>
    );
};

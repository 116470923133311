import { PersonalDetails, irelandCountryCode } from 'common';

export type DigitalSignFormValues = {
    bankAccountHolder: string;
    email: string;
    mobilePhone: {
        number: string;
        countryCode: string;
    };
};

export const getInitialValues = (personalDetails?: PersonalDetails): DigitalSignFormValues => ({
    bankAccountHolder: personalDetails?.identification.name || '',
    email: personalDetails?.contactDetails.email || '',
    mobilePhone: {
        number: personalDetails?.contactDetails.mobilePhone?.number || '',
        countryCode: personalDetails?.contactDetails.mobilePhone?.countryCode || irelandCountryCode,
    },
});

import React, { useState } from 'react';
import { Button, Fieldset, Form, Layout, Modal } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import {
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsHookPageViewTracker,
    ValidatedInput,
} from '@cp-shared-6/frontend-ui';
import { useTranslation } from 'react-i18next';
import { contractNumberFormValidationSchema } from './contract-number-form/validation';
import { CpDataApi } from '../../../../cp-xhr';
import { getContractNumberVerificationEndpoint } from 'common';

export type FormValues = {
    contractNumber: string;
};

enum MessageType {
    ERROR = 'ERROR',
    CONTRACT_NOT_FOUND = 'CONTRACT_NOT_FOUND',
    NONE = 'NONE',
}

type KycContractNumberProps = {
    setContractNumber: (contractNumber: string) => void;
    onSubmitSuccess: (lastFourDigitsOfPhoneNumber: string, customerId: string) => void;
};

export const KycContractNumber: React.FC<KycContractNumberProps> = ({ setContractNumber, onSubmitSuccess }) => {
    const { t } = useTranslation('contract-number-hookpage');
    const [isLoading, setIsLoading] = useState(false);
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);

    useAnalyticsHookPageViewTracker('kycHookPage', true, '');
    const { onAction: onContractNotFound } = useAnalyticsActionTracker('onKycHookPageNoContract');
    const { onAction: onServicePhoneNumber } = useAnalyticsActionTracker('onKycHookPageServicePhoneNumber');
    if (messageType === MessageType.CONTRACT_NOT_FOUND) onContractNotFound();

    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    const submitForm = (values: FormValues): void => {
        setContractNumber(values.contractNumber);
        setIsLoading(true);
        CpDataApi.post(getContractNumberVerificationEndpoint(), { contractNumber: values.contractNumber })
            .then(response => {
                setIsLoading(false);
                if (response.data.status === 'success') {
                    onSubmitSuccess(response.data.lastFourDigitsOfPhoneNumber, response.data.customerId);
                }
                if (response.data.status === 'failure') {
                    setMessageType(
                        response.data.error === 'CONTRACT_NOT_FOUND'
                            ? MessageType.CONTRACT_NOT_FOUND
                            : MessageType.ERROR,
                    );
                }
            })
            .catch(() => {
                setIsLoading(false);
                setMessageType(MessageType.ERROR);
            });
    };

    return (
        <>
            {isLoading && <Spinner fullPage />}
            <Layout.Item default={'1/1'} className={'u-text-center'}>
                <h1>{t('title')}</h1>
                <p>{t('subtitle')}</p>
                <p
                    dangerouslySetInnerHTML={{ __html: t('subtitle-customer-service') }}
                    onClick={onServicePhoneNumber}
                />
            </Layout.Item>
            <Layout.Item>
                <Formik
                    initialValues={{ contractNumber: '' }}
                    validationSchema={contractNumberFormValidationSchema(t)}
                    onSubmit={submitForm}
                >
                    {formik => (
                        <Form onSubmit={preventSubmit()}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ValidatedInput
                                        label={t('inputTitle')}
                                        testId="contractNumber"
                                        name="contractNumber"
                                        tooltip={t('tooltip')}
                                    />
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <Button full onClick={formik.submitForm} testId="submitButton">
                                        {t('form.proceed')}
                                    </Button>
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    )}
                </Formik>
                <Modal
                    shown={messageType === MessageType.ERROR}
                    status="error"
                    title={t('modal.error.title')}
                    onClose={handleModalClose}
                    onConfirm={handleModalClose}
                    buttonConfirmText={t('modal.button')}
                    testId={'error-modal'}
                >
                    <div>
                        {t('modal.error.text')}
                        <a href={`tel:${t('modal.error.phoneNumber')}`} onClick={onServicePhoneNumber}>
                            {t('modal.error.phoneNumber')}
                        </a>
                    </div>
                </Modal>
                <Modal
                    shown={messageType === MessageType.CONTRACT_NOT_FOUND}
                    status="error"
                    title={t('modal.contract-not-found.title')}
                    onClose={handleModalClose}
                    onConfirm={handleModalClose}
                    buttonConfirmText={t('modal.button')}
                    testId={'contract-not-found-modal'}
                >
                    {t('modal.contract-not-found.text')}
                </Modal>
            </Layout.Item>
        </>
    );
};

import { MarketingPreference, MarketingPreferences as MarketingPreferencesTypes } from 'common';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorNotification } from '../../notifications/error/ErrorNotification';
import { useMyProfile } from '../useMyProfile';
import { BusinessPartnersSection } from './business-partners-section/BusinessPartnersSection';
import { useMarketingPreferences } from './useMarketingPreferences';
import { VWFSSection } from './vwfs-section/VWFSSection';

const areAllValuesAvailable = (marketingPreferences: MarketingPreferencesTypes): boolean => {
    const typesNumber = 2;
    const valuesNumberForEachType = 4;
    return (
        Object.values(marketingPreferences)
            .map((value: MarketingPreference) => Object.values(value))
            .filter(array => array.length === valuesNumberForEachType).length === typesNumber
    );
};

export const MarketingPreferences: React.FC = () => {
    const { t } = useTranslation('my-profile-marketing-preferences');
    const { data: marketingPreferences } = useMarketingPreferences();
    const { data: personalDetails } = useMyProfile();

    const isContactEmailDefined = !!personalDetails?.contactDetails.email;

    return !marketingPreferences || !areAllValuesAvailable(marketingPreferences) ? (
        <DataOverview title={t(`error.title`)}>
            <ErrorNotification
                headline={t('error.headline')}
                text={t('error.text')}
                testId={'marketing-preferences-no-data-error'}
            />
        </DataOverview>
    ) : (
        <>
            <VWFSSection
                marketingPreference={marketingPreferences.vwfs}
                isContactEmailDefined={isContactEmailDefined}
            />
            <BusinessPartnersSection
                marketingPreference={marketingPreferences.businessPartners}
                isContactEmailDefined={isContactEmailDefined}
            />
        </>
    );
};

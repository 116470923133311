import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Form, Fieldset } from '@vwfs-bronson/bronson-react';
import { preventSubmit, DataOverview, Spinner } from '@cp-shared-6/frontend-ui';
import { FileUpload } from './file-upload/FileUpload';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Iban, IbanWithFiles } from 'common';
import { ErrorNotification } from '../../../../../notifications/error/ErrorNotification';
import { maxFileSize } from 'config';

export type FileUploadViewProps = {
    iban: Iban;
    toPreviousView: () => void;
    submit: (values: IbanWithFiles) => void;
    isSubmitting?: boolean;
    isSubmitError: boolean;
};

export const FileUploadView: React.FC<FileUploadViewProps> = ({
    toPreviousView,
    submit,
    isSubmitting,
    iban,
    isSubmitError,
}) => {
    const { t } = useTranslation('financial-details');
    const translationPrefix = 'bank-account-section.iban.edit-view.file-upload-view';
    const validFileFormats = ['pdf', 'bmp', 'gif', 'jpeg', 'jpg', 'png', 'tif', 'tiff', 'docx', 'doc'];

    const initialValues: IbanWithFiles = {
        signedBankData: iban.signedBankData,
        allContracts: iban.allContracts,
        ibanProof: [],
        sepaUpload: [],
    };

    const onSubmit = (values: IbanWithFiles): void => {
        submit(values);
    };

    const validationSchema = Yup.object().shape({
        ibanProof: Yup.array()
            .required(t(`${translationPrefix}.validation-errors.no-file-error`))
            .max(3, t(`${translationPrefix}.validation-errors.file-number-error`)),
        sepaUpload: Yup.array()
            .required(t(`${translationPrefix}.validation-errors.no-file-error`))
            .max(3, t(`${translationPrefix}.validation-errors.file-number-error`)),
    });

    return (
        <DataOverview title={t(`${translationPrefix}.title`)} withoutCardEffect={true}>
            {isSubmitError && (
                <ErrorNotification
                    className={'u-mb'}
                    text={
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('bank-account-section.iban.error-notification'),
                            }}
                        />
                    }
                    testId={'error-notification'}
                />
            )}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnBlur={true}
            >
                {formik => (
                    <Form onSubmit={preventSubmit()} data-testid="address-form">
                        <Fieldset>
                            <Fieldset.Row>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: t(`${translationPrefix}.iban-proof.description`),
                                    }}
                                />
                                <FileUpload
                                    name={'ibanProof'}
                                    maxFileSize={maxFileSize}
                                    validFileFormats={validFileFormats}
                                />
                            </Fieldset.Row>
                        </Fieldset>

                        <Fieldset>
                            <Fieldset.Row>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: t(`${translationPrefix}.sepa-upload.description`),
                                    }}
                                />
                                <FileUpload
                                    name={'sepaUpload'}
                                    maxFileSize={maxFileSize}
                                    validFileFormats={validFileFormats}
                                />
                            </Fieldset.Row>
                        </Fieldset>
                        <ButtonContainer center>
                            <Button secondary onClick={toPreviousView} testId="edit-address-back" type="button">
                                {t('translation:editable-section-nav.back')}
                            </Button>
                            {isSubmitting ? (
                                <Spinner center />
                            ) : (
                                <Button onClick={formik.submitForm} testId="edit-address-submit">
                                    {t('translation:editable-section-nav.submit')}
                                </Button>
                            )}
                        </ButtonContainer>
                    </Form>
                )}
            </Formik>
        </DataOverview>
    );
};

import { Address, Contact, Identification, PersonalDetails, Phone } from 'common';

export const IdentificationWithAllValues: Identification = {
    name: 'OLIVER PRAGER',
    id: '07221753Y',
};

export const IdentificationWithOnlyBusinessValues: Identification = {
    name: 'OLIVER PRAGER',
};

export const mobilePhone: Phone = {
    countryCode: '353',
    number: '838289455',
};

export const otherPhone: Phone = {
    countryCode: '353',
    number: '838289455',
};

export const ContactWithAllValues: Contact = {
    email: 'david.torres@digitalunit.berlin',
    mobilePhone,
    otherPhone,
};

export const ContactWithNewValues: Contact = {
    email: 'new@email.pl',
    mobilePhone,
    otherPhone,
};

export const ContactWithEmailOnly: Contact = {
    email: 'david.torres@digitalunit.berlin',
};

export const ContactWithoutEmail: Contact = {
    mobilePhone,
    otherPhone,
};

export const AddressWithAllValues: Address = {
    addressLine1: '16 Temple Gardens',
    addressLine2: 'Liffey Valley',
    townOrCity: 'Clondalkin',
    county: 'DUBLIN',
    postcode: 'A35 F296',
};

export const AddressWithMissingAddressLine: Address = {
    addressLine2: 'Liffey Valley',
    townOrCity: 'Clondalkin',
    county: 'Dublin',
    postcode: 'A35 F296',
};

export const AddressWithMissingAddressLineAndTownOrCity: Address = {
    addressLine2: 'Liffey Valley',
    county: 'Dublin',
    postcode: 'A35 F296',
};

export const AddressWithMissingAddressLinesAndTownOrCity: Address = {
    county: 'Dublin',
    postcode: 'A35 F296',
};

export const AddressWithMissingTownOrCityAndPostcode: Address = {
    addressLine1: '16 Temple Gardens',
    addressLine2: 'Liffey Valley',
    county: 'Dublin',
};

export const MyProfileWithAllValues: PersonalDetails = {
    identification: IdentificationWithAllValues,
    contactDetails: ContactWithAllValues,
    address: AddressWithAllValues,
};

export const examplePhoneValidationResponseData = {
    isMobile: true,
    isValid: true,
    carrier: 'Three Ireland (Hutchison) Ltd',
    city: 'Countrywide',
    cleansedPhoneNumber: '+353838289455',
    cleansedPhoneNumberNational: '838289455',
    country: 'IE',
    phoneCountryCode: '+353',
    phoneType: 'MOBILE',
};

import { DealerDetails } from 'common';
import React from 'react';
import { isEmpty } from 'lodash';
import { IconList } from '@cp-shared-6/frontend-ui';

export const DealerInformationText: React.FC<{ dealerDetails?: DealerDetails }> = ({ dealerDetails }) => {
    if (!dealerDetails || isEmpty(dealerDetails)) {
        return null;
    }
    const { name, phoneNumber, billingAddress, website, email } = dealerDetails;
    const { street, city, state, postalCode } = billingAddress;
    const getAddress = (): React.ReactNode => {
        return (
            <p>
                {street && (
                    <>
                        {street}, <br />
                    </>
                )}
                {city && (
                    <>
                        {city}, <br />
                    </>
                )}
                {state && (
                    <>
                        {state} <br />
                    </>
                )}
                {postalCode && <>{postalCode}</>}
            </p>
        );
    };
    const phoneLabel = !!phoneNumber && (
        <p>
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </p>
    );
    const addressLabel = billingAddress ? getAddress() : '';
    const websiteLabel = !!website && (
        <p>
            <a href={website} target="_blank" rel="noopener noreferrer">
                {website}
            </a>
        </p>
    );
    const mailLabel = !!email && (
        <p>
            <a href={`mailto:${email}`}>{email}</a>
        </p>
    );

    const items = [
        {
            label: phoneLabel,
            icon: phoneNumber ? 'phone' : '',
        },
        {
            label: addressLabel,
            icon: billingAddress ? 'location' : '',
        },
        {
            label: websiteLabel,
            icon: website ? 'news' : '',
        },
        {
            label: mailLabel,
            icon: email ? 'mail' : '',
        },
    ];

    return (
        <>
            {!!name && (
                <p>
                    <b>{name}</b>
                </p>
            )}
            <IconList items={items} />
        </>
    );
};

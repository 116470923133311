import { GreetingMessage, getGreetingEndpoint } from 'common';
import { getSimpleDataFetchSlice } from '@cp-shared-6/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';

const { reducer, fetchData } = getSimpleDataFetchSlice<GreetingMessage, DefaultBusinessMarketApiErrorCode>({
    dataName: 'greeting',
    fetchCallback() {
        return CpDataApi.get(getGreetingEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchGreeting = fetchData;

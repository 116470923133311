import { getCustomerHappinessIndexEndpoint } from 'common';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-6/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-6/frontend-storybook-extensions';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { mockCustomerHappinessIndexResponse } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: mockCustomerHappinessIndexResponse,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const CustomerHappinessIndexMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getCustomerHappinessIndexEndpoint(),
};

export const CustomerHappinessIndexResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [CustomerHappinessIndexMock]);

    return <div>{storyFn()}</div>;
};

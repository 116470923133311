import { countryCodes, irelandCountryCode } from 'common';
import { Combobox, FormField } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { ComboboxOption, ComboboxState } from '../../../../../../types/bronson-react';

export const CountryCodeSelection: React.FC<{ name: string; testId: string; disabled?: boolean }> = ({
    name,
    testId,
    disabled,
}) => {
    const [field, , helpers] = useField(name);

    const setValueChange = useCallback((selectedCountryCode: ComboboxOption[]): void => {
        return helpers.setValue(selectedCountryCode[0].key);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const options = countryCodes.map(({ dialCode, name }: { dialCode: string; name: string }, index: number) => {
        return (
            <Combobox.Item
                testId={`contract-selection-item-${dialCode}`}
                optionValue={`+${dialCode}`}
                optionKey={dialCode}
                key={`${dialCode}${index}`}
            >
                <span>
                    {name} <b>+{dialCode}</b>
                </span>
            </Combobox.Item>
        );
    });

    const defaultState: ComboboxState = {
        value: [
            {
                value: `+${field.value || irelandCountryCode}`,
                key: field.value || irelandCountryCode,
            },
        ],
    };

    return (
        <FormField type="select" id={'country-code-selection'}>
            <Combobox
                testId={`${testId}country-code-selection`}
                defaultState={defaultState}
                onChange={setValueChange}
                disabled={disabled}
            >
                {options}
            </Combobox>
        </FormField>
    );
};

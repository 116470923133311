import React from 'react';
import { useTranslation } from 'react-i18next';
import { Address } from 'common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-6/frontend-ui';

export const AddressList: React.FC<{ address: Address }> = ({ address }) => {
    const { t } = useTranslation('my-profile-address-details');

    const { addressLine1, addressLine2, townOrCity, county, postcode } = address;

    const isAddressEmpty = !addressLine1 && !addressLine2;
    const areAllValuesEmpty = isAddressEmpty && !townOrCity;

    const getValue = (value?: string): string | undefined => (areAllValuesEmpty ? '-' : value);

    const addressList: DefinitionListItem[] = [
        {
            label: t('consult-view.first-address-line'),
            value: getValue(addressLine1),
            testId: 'address-first-address-line',
        },
        {
            label: t('consult-view.second-address-line'),
            value: getValue(addressLine2),
            testId: 'address-second-address-line',
        },
        {
            label: t('consult-view.town-or-city'),
            value: getValue(townOrCity),
            testId: 'address-town-or-city',
        },
        {
            label: t('consult-view.county'),
            value: getValue(county),
            testId: 'address-county',
        },
        {
            label: t('consult-view.postcode'),
            value: getValue(postcode),
            testId: 'address-postcode',
        },
    ].filter(({ value }): boolean => Boolean(value));

    return <DefinitionList split={true} list={addressList} />;
};

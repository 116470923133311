import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Contact } from 'common';
import { Notification, NotificationStatus } from '@cp-shared-6/frontend-ui';
import { ContactList } from './ContactList';

import { ErrorNotification } from '../../../notifications/error/ErrorNotification';
import { EditStatus } from '../EditStatus';

export type ConsultViewProps = {
    contact?: Contact;
    startEditing: () => void;
    lastEditStatus: EditStatus;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ contact, startEditing, lastEditStatus }) => {
    const { t } = useTranslation('my-profile-contact-details');

    if (!contact) {
        return null;
    }

    const editButtonProps = { onClick: startEditing, secondary: true, icon: '', link: false, testId: 'edit-button' };

    const notification =
        lastEditStatus === EditStatus.SUCCESS ? (
            <Notification
                status={NotificationStatus.warning}
                headline={t('edit-view.notification-success.headline')}
                text={t('edit-view.notification-success.text')}
                testId={'success-notification'}
            />
        ) : lastEditStatus === EditStatus.PHONE_VALIDATION_ERROR ? (
            <ErrorNotification
                text={
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('edit-view.notification-error.phone'),
                        }}
                    />
                }
                testId={'error-notification-phone'}
            />
        ) : (
            <ErrorNotification
                text={
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('edit-view.notification-error.text'),
                        }}
                    />
                }
                testId={'error-notification-text'}
            />
        );

    const notificationInfoText = (
        <span
            dangerouslySetInnerHTML={{
                __html: t('consult-view.notification-info'),
            }}
        />
    );

    return (
        <DataOverview
            title={t('consult-view.title')}
            buttonLabel={t('translation:editable-section-nav.start')}
            buttonProps={editButtonProps}
        >
            <Notification status={NotificationStatus.info} className={`u-mb`}>
                {notificationInfoText}
            </Notification>
            <ContactList contact={contact} />
            {lastEditStatus !== EditStatus.NOT_PERFORMED && notification}
        </DataOverview>
    );
};

import { FinancialDetails } from 'common';
import { formatCpDate } from '@cp-shared-6/common-utilities';

export const FinancialDetailsWithAllValues: FinancialDetails = {
    isActive: true,
    startDate: formatCpDate('2019-10-11T09:44:57.299').toCpDate(),
    endDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    term: 36,
    iban: 'IE29AIBK93115212345678',
    monthlyPaymentDay: 15,
    _links: {
        changeIban: '/change-iban',
        downloadSepa: '/downloadSepa',
        digitalSign: '/digital-sign',
    },
    financedAmount: 24764,
    guaranteedMinimumFutureValue: 11647,
    remainingTerm: 1,
    totalDeposit: 6500,
    remainingNumberOfPayments: 12,
    finalPaymentDate: formatCpDate('2022-04-20').toCpDate(),
    finalPaymentAmount: 2022.12,
    currentSettlementAmount: 12000.99,
    nextPaymentAmount: 212.33,
};

export const FinancialDetailsWithAllValuesAndLinks: FinancialDetails = {
    isActive: true,
    startDate: formatCpDate('2019-10-11T09:44:57.299').toCpDate(),
    endDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    term: 36,
    iban: 'IE74AIBK*********5678',
    monthlyPaymentDay: 15,
    _links: { changePaymentDay: 'change.payment.day.url' },
    financedAmount: 24764,
    guaranteedMinimumFutureValue: 11647,
    remainingTerm: 1,
    totalDeposit: 6500,
    remainingNumberOfPayments: 12,
    finalPaymentDate: formatCpDate('2022-04-20').toCpDate(),
    finalPaymentAmount: 2022.12,
    currentSettlementAmount: 12000.99,
    nextPaymentAmount: 212.33,
};

export const FinancialDetailsWithTermEqualToZero: FinancialDetails = {
    isActive: true,
    startDate: formatCpDate('2019-10-11T09:44:57.299').toCpDate(),
    endDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    term: 0,
    iban: 'IE74AIBK*********5678',
    monthlyPaymentDay: 15,
    financedAmount: 24764,
    guaranteedMinimumFutureValue: 11647,
    remainingTerm: 1,
    totalDeposit: 6500,
    remainingNumberOfPayments: 12,
    finalPaymentDate: formatCpDate('2022-04-20').toCpDate(),
    finalPaymentAmount: 2022.12,
    currentSettlementAmount: 12000.99,
    nextPaymentAmount: 212.33,
};

export const FinancialDetailsWithMissingValues: FinancialDetails = {
    isActive: true,
    endDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
};

export const FinancialDetailsInactiveContract: FinancialDetails = {
    isActive: false,
    startDate: formatCpDate('2019-10-11T09:44:57.299').toCpDate(),
    endDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    term: 36,
    iban: 'IE74AIBK*********5678',
    monthlyPaymentDay: 15,
    financedAmount: 24764,
    guaranteedMinimumFutureValue: 11647,
    remainingTerm: 1,
    totalDeposit: 6500,
    remainingNumberOfPayments: 0,
    finalPaymentDate: formatCpDate('2022-04-20').toCpDate(),
    finalPaymentAmount: 0,
    currentSettlementAmount: 0,
    nextPaymentAmount: 212.33,
};

import React from 'react';
import { FinancialDetails } from 'common';
import {
    DataOverview,
    DefinitionList,
    DefinitionListItem,
    Notification,
    NotificationStatus,
} from '@cp-shared-6/frontend-ui';
import { useTranslation } from 'react-i18next';
import { EditStatus } from '../EditStatus';
import { ErrorNotification } from '../../../../notifications/error/ErrorNotification';
import useMobileDetect from './useMobileDetect';

export type ConsultViewProps = {
    startEditing: () => void;
    details: FinancialDetails;
    lastEditStatus: EditStatus;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ details, lastEditStatus, startEditing }) => {
    const { t } = useTranslation('financial-details');
    const translationPrefix = 'payment-day-section.consult-view';
    const isChangeAllowed = !!details._links?.changePaymentDay;
    const { isMobile } = useMobileDetect();
    const itemList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.monthly-payment-day.label`),
            value: details.monthlyPaymentDay?.toString(),
            tooltip: t(`${translationPrefix}.monthly-payment-day.tooltip`),
            testId: 'monthly-payment-day',
        },
    ];

    const notification =
        lastEditStatus === EditStatus.SUCCESS ? (
            <Notification
                status={NotificationStatus.warning}
                headline={t('payment-day-section.edit-view.notification-success.headline')}
                testId={'success-notification'}
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: t('payment-day-section.edit-view.notification-success.text'),
                    }}
                />
            </Notification>
        ) : lastEditStatus === EditStatus.NOT_CHANGED ? (
            <Notification
                status={NotificationStatus.warning}
                headline={t('payment-day-section.edit-view.notification-no-changes.headline')}
                text={t('payment-day-section.edit-view.notification-no-changes.text')}
                testId={'not-changed-notification'}
            />
        ) : (
            <ErrorNotification
                text={
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('payment-day-section.edit-view.notification-error.text'),
                        }}
                    />
                }
                testId={'error-notification'}
            />
        );

    return (
        <DataOverview
            title={t(`${translationPrefix}.headline`)}
            withoutCardEffect={true}
            buttonLabel={isChangeAllowed ? (isMobile ? ' ' : t('translation:editable-section-nav.start')) : undefined}
            buttonProps={
                isChangeAllowed ? { onClick: startEditing, testId: 'edit-button', secondary: true } : undefined
            }
        >
            <DefinitionList split={true} list={itemList} />
            {lastEditStatus !== EditStatus.NOT_PERFORMED && notification}
        </DataOverview>
    );
};

import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Fieldset, Layout, ContentSection, Button, ButtonContainer, Spinner, Modal } from '@vwfs-bronson/bronson-react';
import { useAnalyticsActionTracker } from '@cp-shared-6/frontend-ui';
import { ContractInfo, RequestCategory, getRequestSendingEndpoint, PersonalDetails } from 'common';
import { getInitialValues, RequestInitialValues } from './initialValues';
import { ContractSelection } from './contract-selection/ContractSelection';
import { CategorySelection } from './category-selection/CategorySelection';
import { validationSchema } from './validationSchema';
import { CategoryView } from './category-selection/category-view/CategoryView';
import { CpDataApi } from '../../../cp-xhr';
import { dashboardPagePath } from '../../navigation/paths';

enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    NONE = 'NONE',
}

export const FormView: React.FC<{ contracts?: ContractInfo[]; personalDetails?: PersonalDetails }> = ({
    contracts,
    personalDetails,
}) => {
    const { t } = useTranslation('request');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const history = useHistory();

    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');

    if (!contracts) {
        return null;
    }

    const customerEmail = personalDetails?.contactDetails.email;

    const handleSubmit = (values: RequestInitialValues, { resetForm }: { resetForm: Function }): void => {
        setIsSubmitting(true);
        const { contractSelection, categorySelection, balanceEnquiryForm, refundQuery } = values;

        const baseRequest = {
            ...contractSelection,
            requestCategory: categorySelection,
        };
        const requestBody = (): object => {
            let requestExtension: {};
            switch (categorySelection) {
                case 'BALANCE_ENQUIRY_FORM':
                    requestExtension = balanceEnquiryForm;
                    break;
                case 'REFUND_QUERY':
                    requestExtension = refundQuery;
                    break;
                default:
                    requestExtension = {};
                    break;
            }
            return { ...baseRequest, ...requestExtension };
        };

        const trackingCategory = `Category: ${values.categorySelection.replaceAll('_', ' ').toLowerCase()}`;

        CpDataApi.post(getRequestSendingEndpoint(), requestBody())
            .then(() => {
                onSuccess(trackingCategory);
                setIsSubmitting(false);
                resetForm();
                setMessageType(MessageType.SUCCESS);
            })
            .catch(() => {
                onError(trackingCategory);
                setIsSubmitting(false);
                setMessageType(MessageType.ERROR);
            });
    };
    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    const handleSuccessModalConfirm = (): void => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    return (
        <>
            <Formik
                initialValues={getInitialValues(customerEmail)}
                validationSchema={validationSchema(t)}
                onSubmit={handleSubmit}
                validateOnBlur={true}
            >
                {({ values, submitForm }): JSX.Element => (
                    <Form onSubmit={(e): void => e.preventDefault()} data-testid="request-form">
                        <ContentSection className={'request-certificate-form'}>
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item>
                                            <ContractSelection contracts={contracts} />
                                        </Layout.Item>
                                        <Layout.Item>
                                            <CategorySelection
                                                isContractSelected={!!values.contractSelection.contractNumber}
                                            />
                                        </Layout.Item>
                                        <CategoryView category={values.categorySelection as RequestCategory} />
                                    </Layout>
                                </Fieldset.Row>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button
                                            secondary
                                            testId={'dashboard-button'}
                                            type="button"
                                            onClick={(): void => {
                                                history.push(dashboardPagePath());
                                            }}
                                        >
                                            {t('dashboard-button')}
                                        </Button>
                                        {isSubmitting ? (
                                            <Spinner small />
                                        ) : (
                                            <Button testId={'submit-button'} type="submit" onClick={submitForm}>
                                                {t('submit-button')}
                                            </Button>
                                        )}
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                        </ContentSection>
                    </Form>
                )}
            </Formik>
            <Modal
                shown={messageType === MessageType.ERROR}
                status="error"
                title={t('modal-error.headline')}
                onClose={handleModalClose}
                onCancel={handleModalClose}
                buttonCancelText={t('modal-error.button')}
                testId={'error-modal'}
            >
                <p
                    dangerouslySetInnerHTML={{
                        __html: t('modal-error.text'),
                    }}
                />
            </Modal>
            <Modal
                shown={messageType === MessageType.SUCCESS}
                status="success"
                title={t('modal-success.headline')}
                onClose={handleModalClose}
                onConfirm={handleSuccessModalConfirm}
                buttonConfirmText={t('modal-success.button')}
                testId={'success-modal'}
            >
                {t('modal-success.text')}
            </Modal>
        </>
    );
};

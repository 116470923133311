import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { validPhoneNumberRegExp } from 'common';

export const validationSchema = (t: TFunction) => {
    const requiredEmailError = 'edit-view.email.validation.required';
    const invalidEmailError = 'edit-view.email.validation.invalid';
    const requiredMobilePhoneError = 'edit-view.mobile-phone.validation.required';
    const invalidMobilePhoneError = 'edit-view.mobile-phone.validation.invalid';
    const invalidOtherPhoneError = 'edit-view.other-phone.validation.invalid';
    return Yup.object().shape({
        email: Yup.string()
            .trim()
            .required(t(requiredEmailError))
            .email(t(invalidEmailError)),
        mobilePhone: Yup.object().shape({
            number: Yup.string()
                .trim()
                .required(t(requiredMobilePhoneError))
                .min(9, t(invalidMobilePhoneError))
                .max(20, t(invalidMobilePhoneError))
                .matches(validPhoneNumberRegExp, t(invalidMobilePhoneError)),
        }),
        otherPhone: Yup.object().shape({
            number: Yup.string()
                .trim()
                .min(9, t(invalidOtherPhoneError))
                .max(20, t(invalidOtherPhoneError))
                .matches(validPhoneNumberRegExp, t(invalidOtherPhoneError)),
        }),
    });
};

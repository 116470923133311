import React from 'react';
import { FileUpload as CommonFileUpload } from 'components/file-upload/FileUpload';
import { useTranslation } from 'react-i18next';
import { formatAsFileSize } from 'common';

export type StepSecondFileUploadProps = {
    maxFileSize: number;
    validFileFormats: string[];
};

export const FileUpload: React.FC<StepSecondFileUploadProps> = ({ maxFileSize, validFileFormats }) => {
    const { t } = useTranslation('my-profile-address-details');
    const translationPrefix = 'edit-view.file-upload-view.validation-errors';
    const sizeError = t(`${translationPrefix}.file-size-error`, { size: formatAsFileSize(maxFileSize, 2) });
    const typeError = t(`${translationPrefix}.file-type-error`, { formats: validFileFormats.join(', ') });
    const sizeAndTypeError = (
        <div>
            <span>{sizeError}</span>
            <br />
            <span>{typeError}</span>
        </div>
    );
    return (
        <CommonFileUpload
            name="files"
            descriptionText="Drag & Drop"
            descriptionSupplementaryText="or click & choose from you computer"
            buttonText="click here"
            multiple={true}
            maxFileSize={maxFileSize}
            validFileFormats={validFileFormats}
            sizeError={sizeError}
            typeError={typeError}
            sizeAndTypeError={sizeAndTypeError}
        />
    );
};

import React from 'react';
import { FileUpload as CommonFileUpload } from 'components/file-upload/FileUpload';
import { useTranslation } from 'react-i18next';
import { formatAsFileSize } from 'common';

export type StepSecondFileUploadProps = {
    maxFileSize: number;
    validFileFormats: string[];
    name: string;
};

export const FileUpload: React.FC<StepSecondFileUploadProps> = ({ maxFileSize, validFileFormats, name }) => {
    const { t } = useTranslation('financial-details');
    const translationPrefix = 'bank-account-section.iban.edit-view.file-upload-view';
    const sizeError = t(`${translationPrefix}.validation-errors.file-size-error`, {
        size: formatAsFileSize(maxFileSize, 2),
    });
    const typeError = t(`${translationPrefix}.validation-errors.file-type-error`, {
        formats: validFileFormats.join(', '),
    });
    const sizeAndTypeError = (
        <div>
            <span>{sizeError}</span>
            <br />
            <span>{typeError}</span>
        </div>
    );
    return (
        <CommonFileUpload
            name={name}
            descriptionText={t(`${translationPrefix}.description-text`)}
            descriptionSupplementaryText={t(`${translationPrefix}.description-supplementary-text`)}
            buttonText={t(`${translationPrefix}.button-text`)}
            multiple={true}
            maxFileSize={maxFileSize}
            validFileFormats={validFileFormats}
            sizeError={sizeError}
            typeError={typeError}
            sizeAndTypeError={sizeAndTypeError}
        />
    );
};

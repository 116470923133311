import React from 'react';
import { LogoutSuccess } from 'common';
import { HeroImage } from '@cp-shared-6/frontend-ui';
import { ContentSection, Layout } from '@vwfs-bronson/bronson-react';
import { MarketingCardComponent } from '../../marketing-cards/ui/marketing-card-component';
import { useLogin } from '../../../auth/useLogin';

type LogoutSuccessUiProps = {
    manualLogout: boolean;
    logoutSuccess?: LogoutSuccess;
};

export const LogoutSuccessUi: React.FC<LogoutSuccessUiProps> = ({ manualLogout, logoutSuccess }) => {
    const login = useLogin();

    if (!logoutSuccess) {
        return null;
    }

    const teaser = manualLogout ? logoutSuccess.manual.teaser : logoutSuccess.auto.teaser;

    return (
        <>
            <HeroImage
                title={teaser.title || ''}
                subTitle={teaser.pretitle}
                imageUrl={teaser.imageUrl}
                buttonText={logoutSuccess.button}
                clickHandler={login}
            />
            <ContentSection pageWrapSize="medium">
                <Layout>
                    <Layout.Item className="u-text-center">
                        <h4>{logoutSuccess.marketingCardsHeadline}</h4>
                    </Layout.Item>
                    <Layout.Item default={'1/3'} s={'1/1'}>
                        <MarketingCardComponent
                            content={logoutSuccess.marketingCards.servicePlan}
                            buttonUrl={logoutSuccess.marketingCards.servicePlan.buttonLink}
                            testId={'service-plan-marketing-card'}
                            minHeight={540}
                            shouldOpenLinkInNewWindow
                        />
                    </Layout.Item>
                    <Layout.Item default={'1/3'} s={'1/1'}>
                        <MarketingCardComponent
                            content={logoutSuccess.marketingCards.financeJourney}
                            buttonUrl={logoutSuccess.marketingCards.financeJourney.buttonLink}
                            testId={'service-plan-marketing-card'}
                            minHeight={540}
                            shouldOpenLinkInNewWindow
                        />
                    </Layout.Item>
                    <Layout.Item default={'1/3'} s={'1/1'}>
                        <MarketingCardComponent
                            content={logoutSuccess.marketingCards.matchmaker}
                            buttonUrl={logoutSuccess.marketingCards.matchmaker.buttonLink}
                            testId={'service-plan-marketing-card'}
                            minHeight={540}
                            shouldOpenLinkInNewWindow
                        />
                    </Layout.Item>
                </Layout>
            </ContentSection>
        </>
    );
};

import React from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Brand, DealerDetails } from 'common';
import { DealerInformationText } from './DealerInformationText';
import { DealerMap } from 'components/dealer-map';

type DealerInformationCardProps = {
    dealerDetails: DealerDetails;
    mostRecentContractBrand: Brand;
};

export const DealerInformationCard: React.FC<DealerInformationCardProps> = ({
    dealerDetails,
    mostRecentContractBrand,
}) => {
    const { name, phoneNumber, billingAddress, website, email } = dealerDetails;
    const { t } = useTranslation('dealer-card');

    if (!name && !phoneNumber && !website && !email && isEmpty(billingAddress)) return null;

    return (
        <article className={'c-card c-card--image u-text-left'}>
            <div className={'c-card__image'}>
                <DealerMap dealerDetails={dealerDetails} height="300px" zoom={11} />
            </div>
            <header className={'c-card__header'}>
                <h4 className={'c-card__title'}>{t('title', { brand: mostRecentContractBrand })}</h4>
            </header>
            <div className={'c-card__body'}>
                <DealerInformationText dealerDetails={dealerDetails} />
            </div>
        </article>
    );
};

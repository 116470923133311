import { MarketingPreference, MarketingPreferences } from 'common';

export const MarketingPreferenceTrue: MarketingPreference = {
    email: true,
    phone: true,
    sms: true,
    post: true,
};

export const MarketingPreferenceEmailTrue: MarketingPreference = {
    email: true,
    phone: false,
    sms: false,
    post: false,
    emailAddress: 'test@vwfs.io',
    confirmPrivacyPolicy: true,
};

export const MarketingPreferenceFalse: MarketingPreference = {
    email: false,
    phone: false,
    sms: false,
    post: false,
};
export const MarketingPreferenceSmsTrue: MarketingPreference = { email: false, phone: false, sms: true, post: false };

export const MarketingPreferenceUndefined: MarketingPreference = {
    email: undefined,
    phone: undefined,
    sms: false,
    post: true,
};

export const MarketingPreferencesWithAllValuesTrue: MarketingPreferences = {
    vwfs: MarketingPreferenceTrue,
    businessPartners: MarketingPreferenceTrue,
};

export const MarketingPreferencesWithAllValuesFalse: MarketingPreferences = {
    vwfs: MarketingPreferenceFalse,
    businessPartners: MarketingPreferenceFalse,
};

export const MarketingPreferencesWithMissingValues: MarketingPreferences = {
    vwfs: MarketingPreferenceUndefined,
    businessPartners: MarketingPreferenceUndefined,
};

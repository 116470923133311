import {
    Brand,
    buildContract,
    ContractDataWithLinks,
    ContractType,
    getDealerDetailsEndpoint,
    getFinancialDetailsEndpoint,
    getVehicleDetailsEndpoint,
} from 'common';
import { formatCpDate } from '@cp-shared-6/common-utilities';

export const ContractWithAllValues: ContractDataWithLinks = {
    isActive: true,
    brand: Brand.VW,
    model: 'GTI',
    contractNumber: '0000123456789101',
    encryptedContractNumber: 'AX9101',
    nextPaymentAmount: 1350.25,
    licensePlate: '201-MH-345678',
    vin: 'WAUZZZ4G1FN076523',
    encryptedVin: 'ENCRYPTED_WAUZZZ4G1FN076523',
    contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    paymentDate: formatCpDate('2019-04-11').toCpDate(),
    endDate: formatCpDate('2019-11-11').toCpDate(),
    outstandingBalance: 360.0,
    isEligibility: true,
    isDealerSelection: true,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789101'),
        vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
        dealerDetails: getDealerDetailsEndpoint('0001111'),
    },
};
export const ContractWithDigitalRenewalEligibile: ContractDataWithLinks = {
    isActive: true,
    brand: Brand.VW,
    model: 'GTI',
    contractNumber: '0000123456789101',
    encryptedContractNumber: 'AX9101',
    nextPaymentAmount: 1350.25,
    licensePlate: '201-MH-345678',
    contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    paymentDate: formatCpDate('2019-04-11').toCpDate(),
    endDate: formatCpDate('2019-11-11').toCpDate(),
    outstandingBalance: 360.0,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789101'),
        vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
    },
    isEligibility: true,
    isDealerSelection: true,
};
export const ContractWithDigitalRenewalNotEligibile: ContractDataWithLinks = {
    isActive: true,
    brand: Brand.VW,
    model: 'GTI',
    contractNumber: '0000123456789101',
    encryptedContractNumber: 'AX9101',
    nextPaymentAmount: 1350.25,
    licensePlate: '201-MH-345678',
    contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    paymentDate: formatCpDate('2019-04-11').toCpDate(),
    endDate: formatCpDate('2019-11-11').toCpDate(),
    outstandingBalance: 360.0,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789101'),
        vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
    },
    isEligibility: false,
    isDealerSelection: false,
};

export const ContractWithBrandVWCV: ContractDataWithLinks = {
    isActive: true,
    brand: Brand.VWCV,
    model: 'GTI',
    contractNumber: '0000123456789101',
    encryptedContractNumber: 'AX9101',
    nextPaymentAmount: 1350.25,
    licensePlate: '201-MH-345678',
    contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    paymentDate: formatCpDate('2019-04-11').toCpDate(),
    endDate: formatCpDate('2019-11-11').toCpDate(),
    outstandingBalance: 360.0,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789101'),
        vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
    },
    isEligibility: true,
    isDealerSelection: true,
    inArrears: false,
};
export const ContractInArrears: ContractDataWithLinks = {
    isActive: true,
    brand: Brand.VW,
    model: 'GTI',
    contractNumber: '0000123456789101',
    encryptedContractNumber: 'AX9101',
    nextPaymentAmount: 1350.25,
    licensePlate: '201-MH-345678',
    vin: 'WAUZZZ4G1FN076523',
    encryptedVin: 'ENCRYPTED_WAUZZZ4G1FN076523',
    contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    paymentDate: formatCpDate('2019-04-11').toCpDate(),
    endDate: formatCpDate('2019-11-11').toCpDate(),
    outstandingBalance: 360.0,
    isEligibility: true,
    isDealerSelection: true,
    inArrears: true,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789101'),
        vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
        dealerDetails: getDealerDetailsEndpoint('0001111'),
    },
};
export const InactiveContractWithMissingCarInformation: ContractDataWithLinks = {
    isActive: false,
    contractNumber: '0000123456789101',
    encryptedContractNumber: 'AX9101',
    nextPaymentAmount: 1350.25,
    contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    paymentDate: formatCpDate('2019-04-11').toCpDate(),
    endDate: formatCpDate('2019-11-11').toCpDate(),
    outstandingBalance: 360.0,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789101'),
        vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
        dealerDetails: getDealerDetailsEndpoint('0001111'),
    },
};

export const ContractWithPCPEndOfContractNotification: ContractDataWithLinks = {
    isActive: true,
    brand: Brand.VW,
    model: 'GTI',
    contractNumber: '0000123456789104',
    encryptedContractNumber: 'AX9104',
    nextPaymentAmount: 1350.25,
    licensePlate: '201-MH-345678',
    vin: 'WAUZZZ4G1FN076523',
    encryptedVin: 'ENCRYPTED_WAUZZZ4G1FN076523',
    contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    paymentDate: formatCpDate('2019-04-11').toCpDate(),
    endDate: formatCpDate('2021-12-01').toCpDate(),
    outstandingBalance: 360.0,
    isEligibility: false,
    _links: {
        financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789101'),
        vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
        dealerDetails: getDealerDetailsEndpoint('0001111'),
    },
};

export const ContractsWithAllValues: ContractDataWithLinks[] = [
    ContractWithAllValues,
    {
        isActive: true,
        brand: Brand.AUDI,
        model: 'A3',
        contractNumber: '0000123456789102',
        encryptedContractNumber: 'AX9102',
        nextPaymentAmount: 350.25,
        licensePlate: '201-MH-345679',
        vin: 'XAUZZZ4G1FN076529',
        encryptedVin: 'ENCRYPTED_XAUZZZ4G1FN076529',
        contractType: ContractType.HIRE_PURCHASE,
        paymentDate: formatCpDate('2019-05-11').toCpDate(),
        endDate: formatCpDate('2019-12-11').toCpDate(),
        outstandingBalance: 1230.0,
        _links: {
            financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789102'),
            vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
        },
    },
    {
        isActive: false,
        brand: Brand.SKODA,
        model: 'Fabia',
        contractNumber: '0000123456789103',
        encryptedContractNumber: 'AX9103',
        nextPaymentAmount: 210.25,
        licensePlate: '201-MH-345680',
        vin: 'WAUZZZ4G1FN076523',
        encryptedVin: 'ENCRYPTED_WAUZZZ4G1FN076523',
        contractType: ContractType.HIRE_PURCHASE,
        paymentDate: formatCpDate('2019-05-11').toCpDate(),
        endDate: formatCpDate('2019-12-11').toCpDate(),
        outstandingBalance: 250.0,
        _links: {
            financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789102'),
            vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
        },
    },
    ContractWithPCPEndOfContractNotification,
];

export const ContractsWithInvalidContracts: ContractDataWithLinks[] = [
    ContractWithAllValues,
    {
        isActive: true,
        model: 'A3',
        contractNumber: '0000123456789102',
        encryptedContractNumber: 'AX9102',
        nextPaymentAmount: 350.25,
        licensePlate: '201-MH-345679',
        contractType: ContractType.HIRE_PURCHASE,
        paymentDate: formatCpDate('2019-05-11').toCpDate(),
        endDate: formatCpDate('2019-12-11').toCpDate(),
        outstandingBalance: 360.0,
        _links: {
            financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789102'),
            vehicleDetails: getFinancialDetailsEndpoint('1234', '0000123456789101'),
            dealerDetails: getDealerDetailsEndpoint('0001111'),
        },
    },
    {
        isActive: false,
        model: 'Fabia',
        contractNumber: '0000123456789103',
        encryptedContractNumber: 'AX9103',
        nextPaymentAmount: 210.25,
        licensePlate: '201-MH-345680',
        contractType: ContractType.HIRE_PURCHASE,
        paymentDate: formatCpDate('2019-05-11').toCpDate(),
        endDate: formatCpDate('2019-12-11').toCpDate(),
        outstandingBalance: 1230.0,
        _links: {
            financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789102'),
            vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
            dealerDetails: getDealerDetailsEndpoint('0001111'),
        },
    },
    {
        isActive: true,
        brand: Brand.VW,
        model: 'GTI',
        nextPaymentAmount: 250.25,
        licensePlate: '201-MH-345680',
        contractType: ContractType.PERSONAL_CONTRACT_PLAN,
        paymentDate: formatCpDate('2019-04-11').toCpDate(),
        endDate: formatCpDate('2019-11-11').toCpDate(),
        outstandingBalance: 120.0,
        _links: {
            financialDetails: getFinancialDetailsEndpoint('', ''),
            vehicleDetails: getVehicleDetailsEndpoint('1234', '0000123456789101'),
            dealerDetails: getDealerDetailsEndpoint('0001111'),
        },
    },
    {
        isActive: false,
        brand: Brand.VW,
        model: 'GTI',
        contractNumber: '0000123456789103',
        encryptedContractNumber: 'AX9103',
        licensePlate: '201-MH-345680',
        _links: {
            financialDetails: getFinancialDetailsEndpoint('1234', '0000123456789103'),
            vehicleDetails: getFinancialDetailsEndpoint('1234', '0000123456789101'),
            dealerDetails: getDealerDetailsEndpoint('0001111'),
        },
    },
];

export const NoContractsAvailable: ContractDataWithLinks[] = [];

export const ContractsWithAllInvalidContracts: ContractDataWithLinks[] = [
    ContractWithAllValues,
    {
        isActive: false,
        brand: Brand.VW,
        contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    },
    {
        isActive: false,
        contractNumber: '0000123456789102',
        contractType: ContractType.PERSONAL_CONTRACT_PLAN,
    },
];

export const buildContractList = (contracts: ContractDataWithLinks[]): ContractDataWithLinks[] => {
    return contracts.map(contract => buildContract(contract));
};

import React, { useState } from 'react';
import {
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-6/frontend-ui';
import { DataOverview, Form } from '@vwfs-bronson/bronson-react';
import { Address, Base64File, counties as defaultCounties, getAddressDetailsEndpoint } from 'common';
import { FormView } from './form-view/FormView';
import { Formik } from 'formik';
import { validationSchema } from './validationSchema';
import { useTranslation } from 'react-i18next';
import { FileUploadView } from './file-upload-view/FileUploadView';
import { CpDataApi } from '../../../../cp-xhr';
import { EditStatus } from '../EditStatus';
import { AddressFormSchema, AddressWithFilesFormSchema, Step } from './edit-view.types';
import { maxFileSize } from 'config';
import { groupByFileSize } from 'components/file-upload/utils';
import { getBase64 } from '../../../../utils';
import { useScrollTo } from '../../../../utils/use-scroll-to';
import { SummaryView } from '../common/summary-view/SummaryView';

export type EditViewProps = {
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus) => void;
    address: Address;
};

export const EditView: React.FC<EditViewProps> = ({ address, cancelEditing, finishEditing }) => {
    const { t } = useTranslation('my-profile-address-details');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [step, setStep] = useState<Step>(Step.FIRST);
    const scrollToRef = useScrollTo(isSubmitting ? 'SUBMITTING_VIEW' : `${step}`, `${Step.FIRST}`, 100);

    const trackingSection = 'Address';
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, trackingSection);
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileAddressTypedIn',
    });
    const { onAction: onFileUploadView } = useAnalyticsActionTracker('onEditProfileDocumentUpload');
    const [showSummary, setShowSummary] = useState(false);

    const nextStep = (): void => {
        setShowSummary(true);
    };
    const onConfirm = (): void => {
        setShowSummary(false);
        setStep(Step.SECOND);
        onFileUploadView(trackingSection);
    };

    const onClose = (): void => {
        setShowSummary(false);
    };

    const previousStep = (): void => {
        setStep(Step.FIRST);
    };

    const getCountyNameByCode = (code?: string): string | undefined =>
        defaultCounties.find(({ countyCode }) => String(countyCode) === code)?.label;

    const getCountyCodeByName = (countyName?: string): string => {
        const county = defaultCounties.find(({ label }) => label.toUpperCase() === countyName?.toUpperCase());
        return !!county ? String(county.countyCode) : '';
    };

    const submit = (address: Address, files: File[]): Promise<void> => {
        const filePromises: Promise<Base64File>[] = files.map(file => getBase64(file));
        return Promise.all(filePromises).then(files => {
            return CpDataApi.put(getAddressDetailsEndpoint(), { address: address, files: files });
        });
    };

    const addressFormSchemaToAddress = (addressFormSchema: AddressFormSchema): Address => ({
        addressLine1: addressFormSchema.address?.addressLine1,
        addressLine2: addressFormSchema.address?.addressLine2,
        townOrCity: addressFormSchema.townOrCity,
        county: getCountyNameByCode(addressFormSchema.countyCode),
        postcode: addressFormSchema.postcode,
    });

    const onSubmit = (values: AddressWithFilesFormSchema): void => {
        const { files, ...addressFormSchema } = values;
        const promises: Promise<void>[] = [];
        setIsSubmitting(true);

        const portions: Array<File[]> = groupByFileSize(files, maxFileSize);

        portions.forEach((files: File[]) => {
            promises.push(submit(addressFormSchemaToAddress(addressFormSchema), files));
        });

        Promise.all(promises)
            .then(() => {
                setIsSubmitting(false);
                finishEditing(EditStatus.SUCCESS);
            })
            .catch(() => {
                setIsSubmitting(false);
                finishEditing(EditStatus.ERROR);
            });
    };

    const initialValues: AddressWithFilesFormSchema = {
        address: {
            addressLine1: address.addressLine1 || '',
            addressLine2: address.addressLine2 || '',
        },
        townOrCity: address.townOrCity || '',
        countyCode: getCountyCodeByName(address.county),
        postcode: address.postcode || '',
        files: [],
    };

    return (
        <section ref={scrollToRef}>
            <DataOverview title={t('edit-view.form-view.title')}>
                <UiBlockingSpinner isBlocking={isSubmitting}>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema(t, step)}
                        onSubmit={onSubmit}
                        validateOnBlur={true}
                    >
                        {({ errors, touched, values }): JSX.Element => (
                            <Form
                                onSubmit={preventSubmit()}
                                data-testid="address-form"
                                onChange={(): void => onTyping(errors, touched)}
                            >
                                {step === Step.FIRST && !showSummary && (
                                    <FormView cancelEditing={cancelEditing} nextStep={nextStep} />
                                )}
                                {step === Step.SECOND && !showSummary && (
                                    <FileUploadView previousStep={previousStep} submit={onSubmit} />
                                )}
                                {showSummary && (
                                    <SummaryView newData={values} onConfirm={onConfirm} onClose={onClose} />
                                )}
                            </Form>
                        )}
                    </Formik>
                </UiBlockingSpinner>
            </DataOverview>
        </section>
    );
};
